import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Grid, Typography } from '@mui/material';
import { FileUploadModal } from 'components/common/FileUploadModal/FileUploadModal';
import { ReactHookFormDropdown } from 'components/common/Form/Dropdown/ReactHookFormDropdown';
import { ReactHookFormTextField } from 'components/common/Form/TextField/ReactHookFormTextField';
import { MAIN_FLOWS, MAX_FILE_SIZE } from 'constants/fileTypes';
import { IDropdownOption } from 'interfaces/Props/IDropdownOption';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { credentialActions } from 'store/slices/credentials/credentialSlice';
import { ILicenseVerificationRequest } from 'store/slices/credentials/credentialState';
import { setErrorWithoutPayload } from 'store/slices/error/errorSlice';
import { ISuccessDisciplineResponse } from 'types/DisciplineResponse';
import { number, object, string } from 'yup';
import { openAlert } from 'store/slices/alertbar/alertbarSlice';
import { fileUploadsActions } from 'store/slices/fileUpload/fileUploadSlice';
import { ReactHookFormStateField } from 'components/common/LocationSearch/ReactHookFormStateField';
import {
  DESCRIPTION_MESSAGE,
  NOTE_MESSAGE,
  TOAST_MESSAGE,
} from 'constants/helperText';
import ClearanceItem, {
  IClearanceItemProps,
} from 'components/ClearanceItem/ClearanceItem';
import { returnClearanceItemMessageFromLink } from 'components/ActionItem/helper';

export const LicenseVerification = ({
  onSubmitProcess,
  setOnSubmitProcess,
  toggleDrawer,
  setActionButtonDisabled,
}: {
  onSubmitProcess?: any;
  setOnSubmitProcess?: any;
  toggleDrawer?: any;
  setActionButtonDisabled?: any;
}) => {
  const dispatch = useAppDispatch();

  const filesUploaded = useAppSelector(state => state.fileUpload.uploadedFiles);
  const selectedClearanceItem = useAppSelector(state => {
    return state.actionItem.selectedClearanceItem;
  });

  const myDisciplineOptions: IDropdownOption[] = [];
  const disciplineList: ISuccessDisciplineResponse = useAppSelector(state => {
    state.lookup.disciplines.items?.map(displine =>
      myDisciplineOptions.push({
        key: displine.disciplineId,
        value: displine.disciplineDescription,
      }),
    );

    return state.lookup.disciplines;
  });

  const hasDropDownOption = disciplineList.items.length > 1;

  const [selectedDisciplineId, setSelectedDisciplineId] = useState<
    number | null
  >(null);

  const [rnLpnMessageShow, setRnLpnMessageShow] = useState<boolean>(false);

  const [clearanceItemProps, setClearanceItemProps] = useState<
    IClearanceItemProps | undefined
  >();

  const isRnOrLpn = selectedDisciplineId === 1 || selectedDisciplineId === 2;

  const validationSchema = object({
    licenseNumber: isRnOrLpn
      ? string()
          .required('Required')
          .matches(/^[0-9a-zA-Z]*$/, 'This field must be alphanumeric')
      : string().notRequired(),
    stateAbbreviation: isRnOrLpn
      ? string().required('Required')
      : string().notRequired(),
    discipline: number().min(1, 'This field is required'),
  });

  const formMethods = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(validationSchema),
  });

  const {
    handleSubmit,
    setValue,
    formState: { isSubmitted },
  } = formMethods;

  const submit = useCallback(
    (formData: FieldValues) => {
      if (isRnOrLpn) {
        const data = {
          licenseNumber: formData.licenseNumber,
          stateAbbreviation: formData.stateAbbreviation,
          disciplineId: selectedDisciplineId,
        } as ILicenseVerificationRequest;
        dispatch(credentialActions.submitLicenseForApprovalAction(data));
        toggleDrawer?.(null, false);
      } else {
        if (!!filesUploaded?.length) {
          dispatch(
            credentialActions.submitCredentialForApprovalFromDrawerAction({
              isLicense: true,
            }),
          );
          toggleDrawer?.(null, false);
        }
      }
    },
    [dispatch, filesUploaded, isRnOrLpn, toggleDrawer],
  );

  const onError = useCallback(() => {
    dispatch(setErrorWithoutPayload(true));
  }, [dispatch]);

  useEffect(() => {
    if (selectedClearanceItem) {
      let clearanceMessageData = returnClearanceItemMessageFromLink(
        selectedClearanceItem,
      );
      setClearanceItemProps({
        id: 'license-update',
        header: clearanceMessageData.title,
        message: clearanceMessageData.description,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedClearanceItem]);

  useEffect(() => {
    return () => {
      dispatch(fileUploadsActions.reset());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (onSubmitProcess) {
      handleSubmit(submit, onError)();
      setOnSubmitProcess?.(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleSubmit, onError, onSubmitProcess, setOnSubmitProcess, submit]);

  useEffect(() => {
    if ((filesUploaded || []).length > 0) {
      dispatch(
        openAlert({
          variant: 'success',
          message: TOAST_MESSAGE.DocumentUploaded,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filesUploaded]);

  useEffect(() => {
    if (isRnOrLpn) {
      setActionButtonDisabled(false);
    } else {
      setActionButtonDisabled(filesUploaded?.length === 0);
    }
  }, [setActionButtonDisabled, filesUploaded, isRnOrLpn]);

  return (
    <FormProvider {...formMethods}>
      <Grid container>
        {clearanceItemProps && (
          <ClearanceItem
            {...clearanceItemProps}
            sx={{
              marginTop: '0px !important',
              marginBottom: '24px',
            }}
            iconProps={{
              height: '44px',
            }}
          />
        )}
        <Grid item xs={12}>
          <Typography
            sx={{
              marginBottom: '12px',
              textTransform: 'uppercase',
            }}
            variant="body1"
            color="system.coolGray"
          >
            EMPLOYEE HEALTH
          </Typography>
          <Typography
            sx={{ marginBottom: '12px' }}
            variant="subtitle1"
            color="system.midnightBlue"
            display={{ xs: 'none', sm: 'block' }}
          >
            Licensure Verification
          </Typography>
          <Typography variant="body1" color="system.coolGray" mb={4}>
            {DESCRIPTION_MESSAGE.LicenseVerification}
          </Typography>
          <Typography
            sx={{ marginBottom: '12px' }}
            variant="subtitle1"
            color="system.midnightBlue"
            display={{ xs: 'none', sm: 'block' }}
          >
            License Information
          </Typography>
        </Grid>
        <Grid item xs={12}>
          {hasDropDownOption && (
            <Grid item xs={12} pb={4}>
              <ReactHookFormDropdown
                label="Discipline"
                name="discipline"
                selectedValue={selectedDisciplineId}
                data={myDisciplineOptions}
                onChange={e => setSelectedDisciplineId(e.target.value)}
                notched={!!selectedDisciplineId}
              />
            </Grid>
          )}
          {isRnOrLpn ? (
            <>
              <Grid item xs={12} pb={4}>
                <ReactHookFormTextField
                  label={'License Number'}
                  name={'licenseNumber'}
                />
              </Grid>
              <Grid item xs={12} pb={4}>
                <ReactHookFormStateField hideIcon name="stateAbbreviation" />
              </Grid>
            </>
          ) : (
            selectedDisciplineId &&
            !isRnOrLpn &&
            !rnLpnMessageShow && (
              <Grid item xs={12} pb={4}>
                <FileUploadModal
                  required
                  canUploadMultiple
                  id={'license-document'}
                  label="Add Documents"
                  isSubmitted={isSubmitted}
                  customMaxFileSizeError={`The selected file exceeds the maximum allowed file size of ${MAX_FILE_SIZE} MB. Optimize file to reduce size or contact your Credentialing Analyst for assistance`}
                  acceptedFileTypes={MAIN_FLOWS}
                />
              </Grid>
            )
          )}
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1" color="system.coolGray">
            {NOTE_MESSAGE.LicenseUpload}
          </Typography>
        </Grid>
      </Grid>
    </FormProvider>
  );
};
