import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { cognitiveJobActions } from 'store/slices/jobs/cognitiveJobSearch/cognitiveJobSlice';
import { useHistory } from 'react-router-dom';
import { AppRouteNames } from 'routes/appRouteNames';
import { Box, Grid } from '@mui/material';
import ProfileLink from 'components/Profile/ProfileLink';
import { ArrowLeftIcon } from 'assets/icons/Arrows/Arrow-Left';
import { theme } from 'styles/theme';
import { createDetailFeatureStyles } from 'components/common/PageLayout/PageLayout.styles';
import { useSmMediaQuery } from 'containers/MobileDesktopContainer/useMediaQuery';
import { MenuIcons } from 'enums/menuIcons';
import { setActivePage } from 'store/slices/navigation/navigationSlice';
import { ACSJobSearchCard } from 'components/Jobs/JobSearch/ACSJobSearchCard';

const RecruiterPicksPage = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const classes = createDetailFeatureStyles();
  const isMobileOrTabletDevice = useSmMediaQuery();
  const scrollToRef = useRef<HTMLDivElement>(null);
  const recruiterPicksJobs = useAppSelector(
    state => state.jobsV2?.recruiterPicksJobs?.jobs,
  );
  const scrollToView = useAppSelector(state => state.jobsV2.scrollToView);

  const handleJobCardClick = (jobID: string | undefined, index: string) => {
    dispatch(cognitiveJobActions.setSourcePage(MenuIcons.RecruiterPicks));
    if (index) dispatch(cognitiveJobActions.setScrollToView(index));
    history.push(`/${AppRouteNames.JOB_SEARCH}/${jobID}`);
  };

  const handleBackToSearch = useCallback(() => {
    dispatch(
      cognitiveJobActions.setScrollToView('homepage-recruiter-picks-jobs'),
    );
    history.push(`/${AppRouteNames.HOME}`);
  }, [history]);

  const subHeaderBarContent = useMemo(() => {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <ProfileLink
          id="back-to-search"
          sx={{ paddingLeft: '28px' }}
          underline={'none'}
          leftIcon={
            <ArrowLeftIcon
              sx={{
                width: '17px',
                height: '17px',
                color: theme.palette.system.skyBlue,
              }}
            />
          }
          onClick={handleBackToSearch}
        >
          Back
        </ProfileLink>
      </Box>
    );
  }, [handleBackToSearch]);

  useEffect(() => {
    if (scrollToView) {
      let scrollPosition = document.getElementById(scrollToView);
      if (scrollPosition) scrollPosition.scrollIntoView();
      dispatch(cognitiveJobActions.setScrollToView(''));
    }
  }, [scrollToView]);

  useEffect(() => {
    dispatch(setActivePage(MenuIcons.RecruiterPicks));
    dispatch(cognitiveJobActions.getRecruiterPicks());
  }, []);

  return (
    <Box
      ref={scrollToRef}
      id="recruiter-picks-jobs"
      sx={{ backgroundColor: theme?.palette?.common?.white }}
    >
      {subHeaderBarContent && !isMobileOrTabletDevice && (
        <Grid
          container
          className={classes?.subHeaderBar}
          sx={{
            borderBottom: `1px solid ${theme.palette.system.border}`,
            height: '60px',
            backgroundColor: 'system.white',
          }}
        >
          {subHeaderBarContent}
        </Grid>
      )}
      <Box pb={10} pt={{ xs: 4, md: 0 }} px={4} maxWidth={824} mx="auto">
        {recruiterPicksJobs?.map((item, index) => (
          <ACSJobSearchCard
            id={`job-search-card-${index}`}
            jobDetails={item}
            onClick={() =>
              handleJobCardClick(item.jobID, `job-search-card-${index}`)
            }
          />
        ))}
      </Box>
    </Box>
  );
};

export default RecruiterPicksPage;
