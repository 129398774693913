import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import JobSearchSkeletonCard from 'components/Jobs/JobSearch/JobSearchSkeletonCard';
import { RecommendedJobSearchCard } from 'components/Jobs/JobSearch/RecommendedJobSearchCard';
import { JobSearchLimit } from 'enums/jobSearchLimit';
import { MenuIcons } from 'enums/menuIcons';
import { UserStatusTypeId } from 'enums/userStatusTypeId';
import { getAutoSubmitShortcuts } from 'helpers/getAutoSubmitShortcuts';
import React, { useCallback, useMemo, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useHistory } from 'react-router-dom';
import { AppRouteNames } from 'routes/appRouteNames';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { cognitiveJobActions } from 'store/slices/jobs/cognitiveJobSearch/cognitiveJobSlice';
import { fetchJobs } from 'store/slices/jobs/jobsSlice';
import { setActivePage } from 'store/slices/navigation/navigationSlice';
import { theme } from 'styles/theme';

export const RecommendedJobCardScroll = ({
  jobs,
  showShortcut = true,
  showCount = true,
  sourcePage = MenuIcons.RecommendedJobs,
}) => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const userId = useAppSelector(state => state.auth.userId);
  const totalJobs = useAppSelector(state => {
    const total = state?.jobs?.paging?.total;
    return total ? +total : total;
  }) as number | undefined;
  const userStatusIsLead = useAppSelector(
    state => state.userProfile?.userStatusTypeId === UserStatusTypeId.LEAD,
  );

  const [offset, setOffset] = useState<number>(0);
  const [listLength, setListLength] = useState(offset);
  const [selectedJobId, setSelectedJobId] = useState<number>();

  const getJobSearchLimit = useCallback(() => {
    if (window.outerWidth < theme.breakpoints.values.md) {
      return JobSearchLimit.MOBILE;
    }
    return JobSearchLimit.WEB;
  }, []);

  const handleJobCardClick = useCallback((jobId: number) => {
    dispatch(cognitiveJobActions.setLoadingState(true));
    dispatch(cognitiveJobActions.setSourcePage(sourcePage));
    history.push(`/${AppRouteNames.JOB_SEARCH}/${jobId}`);
    dispatch(setActivePage(''));
  }, []);

  const fetchMoreData = useCallback(() => {
    const handler = setTimeout(() => {
      let newOffset = offset + getJobSearchLimit();
      setOffset(_prev => newOffset);

      dispatch(
        fetchJobs({
          request: { userId, limit: getJobSearchLimit(), offset: newOffset },
        }),
      );

      setListLength(newOffset);
    }, 1000);

    return () => {
      clearTimeout(handler);
    };
  }, [offset, getJobSearchLimit, dispatch, userId]);

  const hasMore = useMemo(() => {
    let hasMore = false;
    if (totalJobs) hasMore = totalJobs - listLength > getJobSearchLimit();
    return hasMore;
  }, [getJobSearchLimit, listLength, totalJobs]);

  const jobsWithShortcuts = jobs?.map((job, index) => {
    let AUTO_SUBMIT_SHORTCUTS = getAutoSubmitShortcuts;
    const componentKeys = Object.keys(AUTO_SUBMIT_SHORTCUTS);
    const defaultComponent = 'AutoSubmitShortcut';
    const useComponent = index < getJobSearchLimit();

    const component =
      useComponent &&
      (index === 0
        ? AUTO_SUBMIT_SHORTCUTS[defaultComponent]
        : index % 10 === 0 && index !== 0
        ? AUTO_SUBMIT_SHORTCUTS[
            componentKeys[Math.floor(index / 10) % componentKeys.length]
          ]
        : null);

    return { ...job, component };
  });

  const dataLength = offset + getJobSearchLimit();

  let listOfJobs =
    !userStatusIsLead && showShortcut
      ? jobsWithShortcuts
      : jobs.map(job => ({ ...job, component: null }));

  return (
    <Box
      sx={{
        width: '100%',
        marginTop: { xs: '0px', md: '24px' },
      }}
    >
      {showCount && (
        <Box
          sx={{ marginBottom: '12px', paddingLeft: '6px' }}
          display="flex"
          flexDirection="row"
        >
          <Typography
            variant="body1"
            color={theme.palette.system.grayText}
            sx={{ marginRight: '12px' }}
          >
            {`${jobs?.length || 0} jobs found`}
          </Typography>
          {/* hiding for MVP release */}
          {/* <Typography variant="body1" color={theme.palette.system.skyBlue}>
          Save Search
        </Typography> */}
        </Box>
      )}

      <div id="job-card-list-scroll-container">
        <InfiniteScroll
          dataLength={dataLength}
          next={fetchMoreData}
          hasMore={hasMore}
          loader={<JobSearchSkeletonCard />}
        >
          {listOfJobs?.map((item, index) => {
            const AutoSubmitShortcutComponent = item.component;

            return (
              <React.Fragment>
                <RecommendedJobSearchCard
                  id={`job-search-card-${index}`}
                  key={`${item.jobId}-${index}`}
                  selectedJobId={selectedJobId}
                  topJob={item}
                  onClick={() => handleJobCardClick(item.jobId)}
                  isNotStrikeFilter={true}
                  showMap={false}
                />
                {AutoSubmitShortcutComponent && AutoSubmitShortcutComponent}
              </React.Fragment>
            );
          })}
        </InfiniteScroll>
      </div>
    </Box>
  );
};
