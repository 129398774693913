import {
  IPayStatementRequest,
  IPayStatementDownloadRequest,
} from 'interfaces/PayStatement/IPayStatement';
import { http } from 'services/BaseApi';
import { logger } from 'services/logging/appInsights';

export async function fetchPayStatements(timesheetReq: IPayStatementRequest) {
  try {
    let timesheetQuery = `/payments/${timesheetReq.userId}`;

    return timesheetReq.isLocums === true
      ? await http.get<string>(timesheetQuery, {
          headers: { 'x-userType': 'locums' },
        })
      : await http.get<string>(timesheetQuery);
  } catch (error) {
    logger.error(error, 'fetchPayStatements', 'payStatementService.ts');
    return error;
  }
}

export async function fetchPayStatementDownload(
  timesheetReq: IPayStatementDownloadRequest,
) {
  try {
    let query = `/payments/${timesheetReq.userId}/${timesheetReq.paymentId}`;

    return timesheetReq.isLocums === true
      ? await http.get<string>(query, {
          headers: { 'x-userType': 'locums' },
        })
      : await http.get(query);
  } catch (e) {
    logger.error(e, 'fetchPayStatementDownload', 'payStatementService.ts');
    return e;
  }
}
