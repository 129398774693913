import axios from 'axios';
import { useFeatureToggle } from 'components/FeatureToggle/useFeatureToggle';
import { TOAST_MESSAGE } from 'constants/helperText';
import React, { useEffect, useMemo, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { FeatureFlagType } from 'enums/featureFlags';
import { FeatureToggles } from 'enums/featureToggles';
import { CookieService } from 'services/cookie/cookieService';
import { useAppDispatch } from 'store/configureStore';
import { useAppSelector } from 'store/hooks';
import { openAlert } from 'store/slices/alertbar/alertbarSlice';
import { authenticationActions } from 'store/slices/authentication/authenticationSlice';
import useQueryParams from 'utils/hooks/useQueryParams';
import { isLocumsSelector } from 'store/selectors/authSelector';
import { logger } from 'services/logging/appInsights';

export const OneLogin = () => {
  const formRef = React.createRef<HTMLFormElement>();
  const tokenRef = useRef<HTMLInputElement>();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const query = useQueryParams();
  const noRefreshParam = query.get('norefresh');
  const refreshAccessToken =
    noRefreshParam !== 'true' || noRefreshParam === null; // check if we need to refresh access token

  let sessionToken = useAppSelector(state => {
    return state.auth.loginResponse.sessionToken;
  });
  const isLocums: boolean = useAppSelector(isLocumsSelector);
  const featureFlags = useAppSelector(state => state.userProfile.featureFlags);
  // const enableTimeEntry = useMemo(() => {
  //   return featureFlags && featureFlags[FeatureFlagType.Time_Entry]?.isEnabled;
  // }, [featureFlags]);
  const enableTimeEntry = useFeatureToggle(
    FeatureToggles.ENABLE_LOCUMS_TIME_PAY_REIMBURSEMENTS,
  );

  let targetUrl = useAppSelector(state => {
    return state.auth.loginResponse.targetUrl;
  });
  let redirectUrl = useAppSelector(state => state.jobShare.originUrl);
  redirectUrl = isLocums
    ? enableTimeEntry
      ? '/time-entry'
      : redirectUrl
    : redirectUrl;
  const refreshTokenRedux = useAppSelector(state => {
    return state.auth.loginResponse.refreshToken;
  });

  const startPage = isLocums
    ? enableTimeEntry
      ? '/time-entry'
      : '/home'
    : '/home';

  useEffect(() => {
    if (!!sessionToken && !!targetUrl) {
      if (!!tokenRef.current) tokenRef.current.value = sessionToken;
      if (!!formRef.current) formRef.current.action = targetUrl;
      CookieService.clearSessionCookies();
      handleSubmit();
    } else {
      redirectUrl ? history.replace(redirectUrl) : history.replace(startPage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionToken, targetUrl, redirectUrl]);

  const handleSubmit = () => {
    try {
      formRef.current?.submit();
      redirectUrl ? history.replace(redirectUrl) : history.replace(startPage);
      // we can prevent the refreshAccessToken call after a successful login
      if (refreshAccessToken) {
        dispatch(
          authenticationActions.refreshAccessTokenAction({
            refreshToken: refreshTokenRedux,
            correlationId: '',
          }),
        );
      }
    } catch (e) {
      dispatch(
        openAlert({
          variant: 'error',
          message: TOAST_MESSAGE.SomethingWentWrongTryReloading,
        }),
      );
      logger.error(e, 'handleSubmit', 'Onelogin.tsx');
    }
  };

  return (
    <form ref={formRef} method="post">
      <input type="hidden" name="session_token" value={sessionToken} />
    </form>
  );
};
