import moment from 'moment';
import React, { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { MoreButton } from 'components/common/MoreButton/MoreButton';
import { TOAST_MESSAGE } from 'constants/helperText';
import { useMobileMediaQuery } from 'containers/MobileDesktopContainer/useMediaQuery';
import { userCredentials } from 'enums/credential';
import { DrawerContentNames } from 'enums/drawerContentNames';
import { MenuIcons } from 'enums/menuIcons';
import { NotificationInternalRedirectTypes } from 'enums/notificationInternalRedirectTypes';
import { INotification } from 'interfaces/Notifications/INotifications';
import { AppRouteNames } from 'routes/appRouteNames';
import { openAlert } from 'store/slices/alertbar/alertbarSlice';
import { assignmentActions } from 'store/slices/assignments/assignmentSlice';
import { notificationsActions } from 'store/slices/notifications/notificationsSlice';
import { theme } from 'styles/theme';
import mapMenuIconsection from 'utils/mapMenuIconNameSection';
import { setActivePage } from 'store/slices/navigation/navigationSlice';
import { cognitiveJobActions } from 'store/slices/jobs/cognitiveJobSearch/cognitiveJobSlice';
import { JobSearchTab } from 'enums/JobSearchTab';
import { DeepLinkQueryParamsHelper } from 'utils/deepLinkQueryParamsHelper';
import { mergeFilterValues } from 'helpers/jobSearchHelper';
import { DEFAULT_FILTER_TYPES } from 'constants/jobFilter';
import { jobSortTypeText } from 'enums/jobSortTypeText';
import { IACSJobRequest } from 'interfaces/Jobs/IACSJobRequest';

export const NotificationItem = ({
  notification,
  handleClick,
  lastItem,
  isDropdown,
}: {
  notification: INotification;
  handleClick?: any;
  lastItem: boolean;
  isDropdown: boolean;
}) => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const isMobileDevice = useMobileMediaQuery();
  const userId = useAppSelector(state => state.auth.userId);
  const savedSearches =
    useAppSelector(
      state => state.userPreference.userPreference.savedSearches,
    ) || [];

  const handleDeleteClick = event => {
    if (notification && notification.notificationId) {
      dispatch(
        notificationsActions.tempDeleteNotification({
          id: notification.notificationId,
          isRead: notification.isRead,
        }),
      );
      setOpenSuccessSnackbar();
    }
  };

  const handleUnReadClick = event => {
    if (notification && notification.notificationId) {
      dispatch(
        notificationsActions.markNotificationAsUnRead([
          notification.notificationId,
        ]),
      );
    }
  };

  const handleReadClick = event => {
    if (notification && notification.notificationId) {
      dispatch(
        notificationsActions.markNotificationAsRead({
          notifications: [notification.notificationId],
        }),
      );
    }
  };

  const notificationDelete = useCallback(() => {
    if (notification?.notificationId) {
      dispatch(
        notificationsActions.markNotificationAsDelete([
          notification.notificationId,
        ]),
      );
    }
  }, [notification?.notificationId]);

  const setOpenSuccessSnackbar = () => {
    dispatch(
      openAlert({
        variant: 'success',
        message: TOAST_MESSAGE.NotificationRemoved,
        onClose: isfromAction => {
          if (!isfromAction) {
            notificationDelete();
          } else {
            dispatch(notificationsActions.undoDeleteNotification());
          }
        },
        style: {
          width: { xs: '100% !important', sm: '80% !important' },
          'action-button': { padding: '6px' },
        },
        actionButton: {
          text: 'UNDO',
          variant: 'text',
          onAction: () => {
            // The undo functionality has been implemented in the onClose callback by using isfromAction parameter
          },
        },
      }),
    );
  };

  const handleClickNotification = () => {
    handleClick?.();

    if (!notification.isRead) {
      dispatch(
        notificationsActions.markNotificationAsRead({
          notifications: [notification.notificationId],
        }),
      );
    }

    if (notification?.link) {
      if (notification.link?.match(/^AMNOne:\/\//)) {
        const internalLink = notification.link.replace(/^AMNOne:\/\//, '');
        const queryIndex = internalLink.indexOf('?');
        const internalPage = internalLink.substring(
          0,
          queryIndex > -1 ? queryIndex : undefined,
        );

        if (internalPage?.trim()) {
          switch (internalPage.trim().toLowerCase()) {
            case NotificationInternalRedirectTypes.JOB_SEARCH_PAGE:
            case NotificationInternalRedirectTypes.JOB_PAGE:
              history.push(
                internalLink.replace(
                  internalPage,
                  mapMenuIconsection(MenuIcons.Jobs),
                ),
              );
              return;
            case NotificationInternalRedirectTypes.CREDENTIALS_PAGE:
            case NotificationInternalRedirectTypes.CREDENTIALS_ITEM:
              // eslint-disable-next-line no-case-declarations
              const placementId = internalLink
                .match(/PlacementId=\{?[0-9]+\}?/)?.[0]
                ?.match(/[0-9]+/)?.[0];

              history.push(
                internalLink.replace(
                  internalPage,
                  `/${AppRouteNames.TASK_CENTER}?placementId=${placementId}&categoryId=${userCredentials.OpenTasks}`,
                ),
              );
              return;
            case NotificationInternalRedirectTypes.CONTRACT_ADDENDUM_PAGE:
              history.push(
                internalLink.replace(
                  internalPage,
                  mapMenuIconsection(MenuIcons.Assignments),
                ),
              );
              return;
            case NotificationInternalRedirectTypes.CONTACTS_PAGE:
              history.push(
                internalLink.replace(
                  internalPage,
                  mapMenuIconsection(MenuIcons.Contacts),
                ),
              );
              return;
            case NotificationInternalRedirectTypes.REIMBURSEMENTS_PAGE:
              history.push(
                internalLink.replace(
                  internalPage,
                  mapMenuIconsection(MenuIcons.Reimbursements),
                ),
              );
              return;
            case NotificationInternalRedirectTypes.RESOURCES_PAGE:
              history.push(
                internalLink.replace(
                  internalPage,
                  mapMenuIconsection(MenuIcons.Resources),
                ),
              );
              return;
            case NotificationInternalRedirectTypes.INTERVIEW_TIPS:
              // eslint-disable-next-line no-case-declarations
              const interviewLink = internalLink
                .match(/link=.*&?$/)?.[0]
                ?.replace(/^link=/, '');

              if (interviewLink) {
                window.open(interviewLink);
              }
              return;
            case NotificationInternalRedirectTypes.TIME_ENTRY:
              let section = 'open';
              let index = internalLink.indexOf('=');
              if (index > -1) {
                section = internalLink.substring(
                  index + 1,
                  internalLink.length,
                );
              }
              history.push({
                pathname: mapMenuIconsection(MenuIcons.TimeEntry),
                state: { section: section },
              });
              return;
            case NotificationInternalRedirectTypes.ASSIGNMENT_DETAILS:
              let activePlacementId = '';
              const idx = internalLink.indexOf('=');
              if (idx !== -1) {
                activePlacementId = internalLink.substring(idx + 1);
              }
              if (!isNaN(Number(activePlacementId))) {
                const pId = Number(activePlacementId);
                dispatch(assignmentActions.setPlacementId(pId));
                history.push(`/${AppRouteNames.ASSIGNMENTS}`);
                if (isMobileDevice) {
                  dispatch(
                    assignmentActions.setDrawerState(
                      DrawerContentNames.ASSIGNMENT_MOBILE,
                    ),
                  );
                }
              }
              return;
            case NotificationInternalRedirectTypes.RECOMMENDED_JOBS_PAGE:
              history.push(`/${AppRouteNames.RECOMMENDED_JOBS}`);
              dispatch(setActivePage(MenuIcons.RecommendedJobs));
              return;
            case NotificationInternalRedirectTypes.RECRUITER_PICKS_PAGE:
              history.push(`/${AppRouteNames.RECRUITER_PICKS}`);
              dispatch(setActivePage(MenuIcons.RecruiterPicks));
              return;
            case NotificationInternalRedirectTypes.SAVED_SEARCH_ITEM:
              const savedSearchId = DeepLinkQueryParamsHelper(
                notification.link,
                'Id',
              );
              const selectedSavedSearchItem = savedSearches.find(
                item => item.id === savedSearchId,
              );
              if (selectedSavedSearchItem) {
                const request: IACSJobRequest = {
                  userId: userId,
                  pageSize: '40',
                  pageNumber: '1',
                  locationDistance: String(
                    selectedSavedSearchItem.locationDistance,
                  ),
                  locationSearch: selectedSavedSearchItem.locationSearch,
                  keywordSearch: selectedSavedSearchItem.keywordSearch,
                  orderBy:
                    selectedSavedSearchItem.sortOrder ??
                    jobSortTypeText.MAX_PAY_RATE,
                  filterTypes: mergeFilterValues(
                    DEFAULT_FILTER_TYPES,
                    selectedSavedSearchItem?.filters,
                  ),
                };
                dispatch(
                  cognitiveJobActions.setActiveSavedSearch(
                    selectedSavedSearchItem,
                  ),
                );
                dispatch(cognitiveJobActions.fetchJobs(request));
              }
              dispatch(
                cognitiveJobActions.setActiveJobSearchTab(
                  JobSearchTab.SavedSearches,
                ),
              );
              history.push(`/${AppRouteNames.JOB_SEARCH}`);
              dispatch(setActivePage(MenuIcons.Jobs));
              return;
          }
        }
      } else {
        window.open(notification.link);
      }
    }
  };

  const menuItems = useMemo(() => {
    let menus: any = [];

    if (notification?.isRead) {
      menus.push({
        name: 'Mark As Unread',
        onClick: e => {
          handleUnReadClick(e);
        },
      });
    } else {
      menus.push({
        name: 'Mark As Read',
        onClick: e => {
          handleReadClick(e);
        },
      });
    }

    menus.push({
      name: 'Delete Notification',
      onClick: e => {
        handleDeleteClick(e);
      },
    });

    return menus;
  }, [notification?.isRead]);

  return (
    <Grid
      container
      width="calc(100% + 48px)"
      ml={-4}
      mr={-4}
      sx={[
        {
          cursor: 'pointer',
          '&: hover': {
            backgroundColor: 'rgba(0, 0, 0, .04)',
          },
        },
        notification?.isRead
          ? {
              backgroundColor: theme.palette.system.inputBackground,
            }
          : {},
      ]}
      data-testid="notification-item"
      onClick={handleClickNotification}
    >
      <Grid
        container
        flexWrap="nowrap"
        pt={2}
        pb={2}
        px={isDropdown ? 4 : 8}
        mr={0}
        ml={0}
        mb={0}
        borderBottom={`${lastItem ? 0 : 1}px solid ${
          theme.palette.system.border
        }`}
      >
        {!notification?.isRead && (
          <Grid item alignSelf="center" pr="12px">
            <Box
              width="10px"
              height="10px"
              borderRadius="100%"
              sx={{ backgroundColor: theme.palette.system.skyBlue }}
              data-testid="status"
            />
          </Grid>
        )}

        <Grid container item>
          <Grid container item xs={12} pb={1} justifyContent="space-between">
            <Grid item>
              <Typography
                variant="body1"
                color="system.coolGray"
                data-testid="category"
              >
                {notification?.category}
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                variant="body1"
                color="system.coolGray"
                data-testid="created-date"
              >
                {moment(notification?.createDate).fromNow()}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            flexWrap="nowrap"
            sx={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <Grid item sx={{ width: '100%' }}>
              <Typography
                variant="body1"
                color="system.midnightBlue"
                pr={4}
                data-testid="message"
              >
                {notification?.message}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        {!isDropdown && (
          <Grid item sx={{ ml: { xs: 'auto', sm: '16px' } }}>
            <Grid
              item
              xs={12}
              display="flex"
              justifyContent="flex-end"
              alignItems="center"
              width="auto"
              height="100%"
            >
              <MoreButton
                id={'notifications-more-button'}
                menuList={menuItems}
              />
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};
