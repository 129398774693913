import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IErrorResponse } from 'interfaces/Common/IErrorResponse';
import { SuccessResponse } from 'types/SuccessResponse';
import { WorkHistoryState } from './workHistoryState';

const initialState: WorkHistoryState = {
  isEditWorkHistorySuccess: {
    success: false,
  },
  isAddWorkHistorySuccess: {
    success: false,
  },
  isloading: false,
  error: false,
  errorResponse: {
    errorCode: 0,
    errorData: {},
    errorDetails: '',
    errorMessage: '',
    operationId: '',
    correlationId: '',
  },
  downloadError: false,
  isSubmitLoaderActive: false,
  isRequestEditLoading: false,
  isUpdateWorkHistorySuccess: {
    success: false,
  },
  isUpdateWorkHistoryLoading: false,
};

const WorkHistorySlice = createSlice({
  name: 'workHistorySlice',
  initialState,
  reducers: {
    invokeEditWorkHistory(state, action) {
      state.isloading = true;
      state.isRequestEditLoading = true;
    },
    setEditWorkHistory(state, action: PayloadAction<SuccessResponse>) {
      state.isEditWorkHistorySuccess = action.payload;
      state.isloading = false;
      state.isRequestEditLoading = false;
    },
    setLoading(state) {
      state.isloading = true;
    },
    resetEditWorkHistory(state) {
      state.isEditWorkHistorySuccess.success = false;
    },
    addWorkHistory(state, action) {
      state.isAddWorkHistorySuccess.success = false;
      state.isloading = true;
      state.isSubmitLoaderActive = true;
    },
    onAddWorkHistorySuccess(state, action: PayloadAction<SuccessResponse>) {
      state.isAddWorkHistorySuccess = action.payload;
      state.isloading = false;
      state.isSubmitLoaderActive = false;
    },
    resetAddWorkHistory(state) {
      state.isAddWorkHistorySuccess.success = false;
    },
    invokeUpdateWorkHistory(state, action) {
      state.isloading = true;
      state.isUpdateWorkHistoryLoading = true;
    },
    setUpdateWorkHistory(state, action: PayloadAction<SuccessResponse>) {
      state.isEditWorkHistorySuccess = action.payload;
      state.isloading = false;
    },
    onUpdateWorkHistorySuccess(state, action: PayloadAction<SuccessResponse>) {
      state.isUpdateWorkHistorySuccess = action.payload;
      state.isUpdateWorkHistoryLoading = false;
      state.isSubmitLoaderActive = false;
    },
    resetUpdateWorkHistory(state) {
      state.isUpdateWorkHistorySuccess = { success: false };
    },
    setError(state, action: PayloadAction<IErrorResponse>) {
      state.error = true;
      state.errorResponse = {
        errorCode: action.payload.errorCode,
        errorData: action.payload.errorData,
        errorDetails: action.payload.errorDetails,
        errorMessage: action.payload.errorMessage,
        operationId: action.payload.operationId,
        correlationId: action.payload.correlationId,
      };
      state.downloadError = false;
    },
    setErrorWithoutPayload(state, action: PayloadAction<boolean>) {
      state.error = action.payload;
    },
    unSetError() {
      return {
        ...initialState,
      };
    },
  },
});

export const {
  invokeEditWorkHistory,
  setEditWorkHistory,
  setLoading,
  resetEditWorkHistory,
  addWorkHistory,
  onAddWorkHistorySuccess,
  resetAddWorkHistory,
  invokeUpdateWorkHistory,
  onUpdateWorkHistorySuccess,
  resetUpdateWorkHistory,
  setError,
  setErrorWithoutPayload,
  unSetError,
} = WorkHistorySlice.actions;

export default WorkHistorySlice.reducer;
