import { WorkHistoryRequest } from '../../types/CreateWorkHistoryRequest';
import { http } from '../BaseApi';
import { SuccessResponse } from '../../types/SuccessResponse';

export const createWorkHistory = async (
  userId: number,
  payload: WorkHistoryRequest,
): Promise<SuccessResponse> => {
  return await http.post<WorkHistoryRequest>(
    `/userprofile/${userId}/employment-history`,
    payload,
  );
};

export const editWorkHistory = async (
  userId: number,
  payload: WorkHistoryRequest,
): Promise<SuccessResponse> => {
  return await http.put<WorkHistoryRequest>(
    `v2/userprofile/${userId}/employment-history`,
    payload,
  );
};
