import { Box, Grid } from '@mui/material';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import React, { useMemo } from 'react';
import { trackEvent } from 'services/logging/appInsights';
import { theme } from 'styles/theme';
import { IACSJobCardProps } from 'interfaces/Props/IACSJobCardProps';
import { CONFIDENTIAL_LOCATION } from 'constants/jobFilter';
import { JobTag } from 'components/common/JobTag/JobTag';
import { BuildingIcon } from 'assets/icons/Building/BuildingIcon';

import { getMMMddyyyy } from 'helpers/dateFormatter';
import { PinpointFilledIcon } from 'assets/icons/Pinpoint/PinpointFilledIcon';
import { ClockIcon } from 'assets/icons/Clock/ClockIcon';
import { FlagIcon } from 'assets/icons/Flag/FlagIcon';
import CalendarIcon from 'assets/icons/Calendar/CalendarIcon';
import { payRange } from 'helpers/jobSearchHelper';

export const ACSJobSearchCard: React.FC<IACSJobCardProps> = props => {
  const jobID = props?.jobDetails?.jobID;
  const city = props?.jobDetails?.city?.name;
  const stateAbbrev = props?.jobDetails?.state?.abbrev;
  const jobType = props?.jobDetails?.jobType;
  const jobTitle = props?.jobDetails?.jobTitle;

  const isStrike = jobType === 'Strike';
  const discipline = props?.jobDetails?.disciplineSpecialty?.disciplineName;
  const specialty = props?.jobDetails?.disciplineSpecialty?.specialtyName;
  const disciplineAndSpecialty = `${discipline}${
    specialty ? ' - ' + specialty : ''
  }`;

  const minPayRate = props?.jobDetails?.payRate?.minPayRate;
  const maxPayRate = props?.jobDetails?.payRate?.maxPayRate;
  const payRateTypeAbbrev = props?.jobDetails?.payRate?.payRateTypeAbbrev;
  const shift = props?.jobDetails?.shift;
  const tags = props?.jobDetails?.tags;
  const jobTeaser = props?.jobDetails?.jobTeaser;

  const startDate = getMMMddyyyy(
    props?.jobDetails?.startDate?.toString() === undefined
      ? ''
      : props?.jobDetails?.startDate?.toString(),
  );

  const handleClick = () => {
    trackEvent('Job_Clicked');
    if (props.onClick) {
      props.onClick();
    }
  };

  const locationText = useMemo(() => {
    if (!city && !stateAbbrev) return CONFIDENTIAL_LOCATION;
    if (!city) return stateAbbrev;
    if (!stateAbbrev) return city;
    if (isStrike) return stateAbbrev;

    return `${city}, ${stateAbbrev}`;
  }, [city, stateAbbrev, isStrike]);

  const jobTags = useMemo(() => {
    let jobTags = [<></>];
    if ((tags?.length ?? 0) > 0) {
      jobTags =
        tags
          ?.filter(x => x.toLowerCase() !== jobType?.toLowerCase())
          ?.slice(0, 3)
          ?.map((x, idx) => {
            return (
              <JobTag
                id={`job-tag-${idx}`}
                key={`job-tag-${idx}`}
                tagText={x}
                sx={{ marginRight: '12px' }}
              />
            );
          }) ?? [];
    }

    return jobTags;
  }, [tags]);

  const IconText = ({ icon, text }) => {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {icon}
        <Typography
          sx={{
            marginLeft: '6px',
            fontSize: '14',
            lineHeight: '21px',
            color: 'system.coolGray',
          }}
        >
          {text}
        </Typography>
      </Box>
    );
  };

  const pinPointIcon = (
    <PinpointFilledIcon
      viewBox="0 0 18 18"
      sx={{ width: '18px', height: '18px' }}
    />
  );

  const clockIcon = (
    <ClockIcon viewBox="0 0 18 18" sx={{ width: '18px', height: '18px' }} />
  );

  const flagIcon = (
    <FlagIcon viewBox="0 0 18 18" sx={{ width: '18px', height: '18px' }} />
  );

  const calendarIcon = (
    <CalendarIcon
      height="18px"
      width="18px"
      color={theme?.palette?.system?.coolGray}
    />
  );

  const iconFooter = useMemo(() => {
    return (
      <Box
        id="job-card-footer"
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box id="location">
            <IconText icon={pinPointIcon} text={locationText} />
          </Box>

          {!isStrike && shift && (
            <Box
              id="shift"
              marginLeft={4}
              sx={{ display: { xs: 'none', lg: 'flex' } }}
            >
              <IconText icon={clockIcon} text={shift} />
            </Box>
          )}
          {!isStrike && startDate && (
            <Box
              id="start-date"
              marginLeft={4}
              sx={{ display: { xs: 'none', lg: 'flex' } }}
            >
              <IconText icon={flagIcon} text={startDate} />
            </Box>
          )}
          {!isStrike && (
            <Box
              id="contract-length"
              marginLeft={4}
              sx={{ display: { xs: 'none', lg: 'flex' } }}
            >
              <IconText
                icon={calendarIcon}
                text={
                  props?.jobDetails?.duration
                    ? `${props?.jobDetails?.duration} weeks`
                    : 'N/A'
                }
              />
            </Box>
          )}
        </Box>
        {(maxPayRate ?? 0) > 0 || (minPayRate ?? 0) > 0 ? (
          <>
            {
              <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                <Typography
                  color="system.grassGreen"
                  component="div"
                  justifyContent="flex-end"
                  fontWeight="600"
                  data-testid="pay-rate"
                  sx={{ fontSize: '18px', lineHeight: '27px' }}
                >
                  {payRange(minPayRate, maxPayRate)}
                </Typography>
                <Typography
                  variant="body2"
                  component="span"
                  color="system.coolGray"
                  sx={{ marginLeft: '6px' }}
                >
                  {payRateTypeAbbrev ? `/${payRateTypeAbbrev}*` : ' /wk*'}
                </Typography>
              </Box>
            }
          </>
        ) : (
          <Typography
            color="system.grassGreen"
            component="div"
            justifyContent="flex-end"
            fontWeight="600"
            data-testid="pay-rate"
            sx={{ fontSize: '18px', lineHeight: '27px' }}
          >
            Contact for Details
          </Typography>
        )}
      </Box>
    );
  }, [locationText]);

  return (
    <Grid
      container
      sx={{
        padding: '18px 0px',
        [theme.breakpoints.down('md')]: {
          borderTop: `1px solid ${theme?.palette?.system?.border}`,
        },
        [theme.breakpoints.up('md')]: {
          borderTop: `1px solid  ${
            jobID === props.selectedJobId
              ? theme?.palette?.system?.skyBlue
              : theme?.palette?.system?.border
          }`,
        },
      }}
    >
      <Grid item xs={12} sx={{ padding: { xs: '0px 18px', md: '0px' } }}>
        <Card
          id={props.id}
          key={jobID}
          sx={{
            minHeight: '122px',
            cursor: 'pointer',
            width: '100%',
          }}
          onClick={handleClick}
        >
          <Grid
            container
            item
            display="flex"
            flexDirection="row"
            flexWrap="nowrap"
            gap={2}
            xs={12}
          >
            <Grid container item flexDirection="column" sx={{ width: '100%' }}>
              <Grid
                container
                item
                display="flex"
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
              >
                <Grid item alignItems="center" display="flex">
                  {jobType && (
                    <JobTag
                      id={`job-type`}
                      key={`job-type`}
                      tagText={jobType ?? ''}
                      isSelected={true}
                      sx={{ marginRight: '12px' }}
                    />
                  )}
                  {jobTags}
                </Grid>
              </Grid>
              <Grid item flexWrap="nowrap">
                <Typography
                  color={theme?.palette?.system?.grayText}
                  data-testid="title"
                  sx={{
                    fontSize: '16px',
                    lineHeight: '24px',
                    fontWeight: '400',
                    paddingTop:
                      (tags?.length ?? 0) > 0 || jobType ? '12px' : '0px',
                  }}
                >
                  {jobTitle ?? ''}
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  sx={{
                    fontSize: '14px',
                    lineHeight: '21px',
                    color: 'system.coolGray',
                  }}
                >
                  {disciplineAndSpecialty}
                </Typography>
              </Grid>
              {jobTeaser && (
                <Grid item>
                  <Typography
                    sx={{
                      fontSize: '12px',
                      lineHeight: '18px',
                      color: theme?.palette?.system?.grayText,
                    }}
                  >
                    {jobTeaser}
                  </Typography>
                </Grid>
              )}
              <Grid
                item
                gap={1}
                sx={{
                  display: 'flex',
                  alignItems: 'flex-start',
                  marginTop: '12px',
                }}
              >
                <BuildingIcon sx={{ height: '18px ', width: '18px' }} />
                <Typography variant="body1" color="system.coolGray">
                  {props?.jobDetails?.organization?.name}
                </Typography>
              </Grid>
              <Grid
                container
                item
                direction="row"
                padding={0}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  marginTop: '12px',
                }}
              >
                {iconFooter}
              </Grid>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
};
