import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IPayStatementState } from './payStatementState';
import { IPayStatement } from 'interfaces/PayStatement/IPayStatement';

const initialState: IPayStatementState = {
  payStatements: [],
  error: undefined,
};

const getPayStatementsAction = createAction('GET_PAYSTATEMENTS');

const getPayStatementDownloadAction = createAction(
  'GET_PAYSTATEMENT_DOWNLOAD',
  function prepare(paymentId: string) {
    return {
      payload: {
        paymentId,
      },
    };
  },
);

const payStatementSlice = createSlice({
  name: 'payStatementSlice',
  initialState,
  reducers: {
    setPayStatementState(
      state,
      action: PayloadAction<IPayStatementState | undefined>,
    ) {
      return {
        ...state,
        payStatements: action.payload?.payStatements || [],
      };
    },
    setSelectedPayStatement(
      state,
      action: PayloadAction<IPayStatement | undefined>,
    ) {
      return {
        ...state,
        selectedPayStatement: action.payload || {},
      };
    },
    reset() {
      return { ...initialState };
    },
    setError(state, action: PayloadAction<boolean>) {
      state.error = action.payload;
    },
    setPageRefresh(state, action: PayloadAction<boolean>) {
      state.pageRefresh = action.payload;
    },
  },
});

export const payStatementsActions = {
  ...payStatementSlice.actions,
  getPayStatementsAction,
  getPayStatementDownloadAction,
};

export default payStatementSlice.reducer;
