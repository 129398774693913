import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { ArrowLeftIcon } from 'assets/icons/Arrows/Arrow-Left';
import CloseIcon from 'assets/icons/Close/Close';
import { GeneralErrorBoundary } from 'components/ErrorBoundaries/GeneralErrorBoundary';
import EndScreenShareButton from 'components/FaqHelpCenter/ScreenShare/EndScreenShareButton';
import { DrawerButtonTypes } from 'enums/buttons';
import { DrawerHeaderType } from 'enums/headerTypes';
import { IDrawerPanelProps } from 'interfaces/Drawer/IDrawerProps';
import { debounce } from 'lodash';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { desktopWidth } from 'styles/media';
import useQueryParams from 'utils/hooks/useQueryParams';
import { AppAlertbar } from '../Alert/AppAlertbar';
import { AppButton } from '../Button/Button';
import { AppCircularProgress } from '../CircularProgress/CircularProgress';
import { GeneratePDF } from '../PDF/GeneratePDF';
import { DrawerStyles, FooterBackdrop } from './Drawer.styles';

/**
 * This is a generic drawer component with mutiple customization options
 * Check code base for examples of usage (i.e. CredentialPage.tsx)
 * @param onSubmit Callback for submission functionality
 *  - Only use this is you are passing in HTML to the child property (i.e WorkHistory.tsx)
 *  - If passing an existing class React component (that we built) as a child prop to the drawer, all submission functions will be passed to that component as properties
 * @param actionButtonType Primary action button type of {@link DrawerButtonTypes}
 * @param secondaryButtonType Secondary action button type of {@link DrawerButtonTypes}
 * @param children Child component
 *  To handle submission within the child component (i.e. AddEducation.tsx):
 *    - The child component is passed 5 properties
 *      - primaryOnClick: Primary callback function
 *      - toggleDrawer: Drawer toggle callback function
 *      - onSubmitProcess: Boolean flag to show whether submit is in process
 *      - setOnSubmitProcess: Toggle submit flag callback function
 *      - setActionButtonDisabled: Disable action button callback function
 * @param alertId ID to display AppAlertbar within the drawer
 *  - This is helpful for mobile views so the alert fits within the drawer
 *  - i.e. CredentialPage.tsx
 *    - Check out the openAlert function to see the ID being passed
 */

export const DrawerPanel: FC<IDrawerPanelProps> = ({
  contentName = 'Site Panel Template',
  cancelButtonText = 'Cancel',
  middleFooter = null,
  actionButtonText,
  actionButtonType = DrawerButtonTypes.BUTTON,
  secondaryButtonType = DrawerButtonTypes.CANCEL,
  onSubmit,
  onError,
  children,
  toggleDrawer: toggleDrawerProp,
  open: openProp = false,
  primaryOnClick,
  secondaryOnClick,
  previousDrawer,
  primaryBtnDisabled,
  contentStyles,
  contentNameSx = {},
  drawerContainerStyles = {},
  innerContainerStyles = {},
  pdfRef,
  showFooter = true,
  headerType = DrawerHeaderType.Close,
  isSubmitLoaderActive,
  backRightAdornment,
  backRightAdornmentClick,
  centerTitle,
  goBackNotClose = false,
  actionButtonStyles,
  drawerButtonFooterStyle,
  drawerCancelButtonStyle,
  alertId,
  drawerCloseValidation,
  leftBackHeaderClick,
  buttonId = 'submitButton',
  anchor = 'right',
}) => {
  const DrawerClasses = DrawerStyles({ showFooter, centerTitle });
  let matchesMinWidth1024 = useMediaQuery(desktopWidth);
  const query = useQueryParams();

  const [open, setOpen] = useState(!!openProp);
  const [isPDFLoading, setPDFLoading] = useState(false);
  const [actionButtonDisabled, setActionButtonDisabled] = useState(false);

  const isNotStrikeFilter = query.get('filter') !== 'strike';

  const toggleDrawer = useCallback(
    (e, open: boolean) => {
      if (
        !!e &&
        e?.type === 'keydown' &&
        ((e as React.KeyboardEvent)?.key === 'Tab' ||
          (e as React.KeyboardEvent)?.key === 'Shift')
      ) {
        return;
      }

      if (!open) {
        /*
         * This will enable the action button again when drawers close.
         * Used mainly when DrawerContent is switched out independently of the Drawer itself
         */
        setActionButtonDisabled(false);
      }

      setOpen(open);

      toggleDrawerProp?.(e, open);
    },
    [toggleDrawerProp],
  );

  const handleSecondaryInteraction = useCallback(
    e => {
      if (
        !!e &&
        e?.type === 'keydown' &&
        ((e as React.KeyboardEvent)?.key === 'Tab' ||
          (e as React.KeyboardEvent)?.key === 'Shift')
      ) {
        return;
      }
      e.preventDefault();
      secondaryOnClick?.(previousDrawer);
    },
    [previousDrawer, secondaryOnClick],
  );

  useEffect(() => {
    if (openProp) {
      toggleDrawer(null, openProp);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (openProp !== open) {
      toggleDrawer(null, openProp);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openProp, open]);

  const [onSubmitProcess, setOnSubmitProcess] = useState(false);

  const debouncedCallback = debounce((...args) => {
    setOnSubmitProcess(true);
    primaryOnClick?.(...args);
  }, 500);

  const onClickCallback = useCallback(
    (...args) => {
      debouncedCallback(...args);
    },
    [debouncedCallback],
  );

  const onPdFDownloadComplete = (isPdfLoaded: boolean) => {
    setPDFLoading(isPdfLoaded);
    let editIcon = document.getElementById('badge-edit-icon');
    if (editIcon) {
      editIcon.style.visibility = 'visible';
    }
  };

  const DrawerCloseHeader = (
    <Box
      className={DrawerClasses.drawerHeaderWrapper}
      onClick={e => e.stopPropagation()}
      display="flex"
      justifyContent="space-between"
      alignItems="center"
    >
      <Box>
        <Typography
          role="heading"
          aria-label="drawer-content-name-header"
          p={4}
          variant="h4"
          color="system.midnightBlue"
        >
          {contentName}
        </Typography>
      </Box>
      <Box display="flex" alignItems="center">
        {backRightAdornment && (
          <Box onClick={backRightAdornmentClick}>{backRightAdornment}</Box>
        )}
        <Box
          onClick={e =>
            goBackNotClose
              ? handleSecondaryInteraction(e)
              : drawerCloseValidation
              ? drawerCloseValidation()
              : toggleDrawer(e, false)
          }
          onKeyDown={e => toggleDrawer(e, false)}
          className={DrawerClasses.closeIconWrapper}
        >
          <CloseIcon />
        </Box>
      </Box>
    </Box>
  );

  const DrawerBackHeader = (
    <Box p={4} className={DrawerClasses.drawerBackHeaderWrapper}>
      <Button
        id="backArrowBtn"
        variant="text"
        size="xsmall"
        onClick={e => {
          leftBackHeaderClick?.();
          toggleDrawer(e, false);
        }}
        onKeyDown={e => toggleDrawer(e, false)}
        sx={{
          justifyContent: 'flex-start',
          minWidth: 'auto',
          padding: 0,
          height: '16px',
        }}
      >
        <ArrowLeftIcon
          sx={{ width: '16px', height: '16px', color: 'system.coolGray' }}
        />
      </Button>
      <Box
        sx={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}
      >
        <Typography
          ml="30px"
          role="heading"
          aria-label="drawer-content-name-header"
          variant="h4"
          color="system.midnightBlue"
          textOverflow="ellipsis"
          sx={{ ...contentNameSx }}
          noWrap
        >
          {contentName}
        </Typography>
        {backRightAdornment && (
          <Box onClick={backRightAdornmentClick}>{backRightAdornment}</Box>
        )}
      </Box>
    </Box>
  );

  const DrawerContent = (
    <Box
      className={DrawerClasses.drawerContent}
      sx={contentStyles}
      id="drawerContent"
    >
      {React.Children.map(children, child => {
        return (
          <GeneralErrorBoundary>
            {React.cloneElement(child as React.ReactElement<any>, {
              primaryOnClick,
              toggleDrawer,
              onSubmitProcess,
              setOnSubmitProcess,
              setActionButtonDisabled,
            })}
          </GeneralErrorBoundary>
        );
      })}
    </Box>
  );

  const DrawerButtonFooter = (
    <Box
      className={DrawerClasses.buttonFooter}
      sx={{ ...drawerButtonFooterStyle }}
    >
      <Box className={DrawerClasses.buttonFooterBlurBackdropWrapper}>
        <FooterBackdrop />
      </Box>
      <Box
        role="button"
        aria-label="drawer-cancel-button"
        className={DrawerClasses.cancelButton}
        sx={{
          ...drawerCancelButtonStyle,
        }}
      >
        {(() => {
          switch (secondaryButtonType) {
            case DrawerButtonTypes.BACK:
              return (
                <AppButton
                  onClick={e => handleSecondaryInteraction(e)}
                  onKeyDown={e => handleSecondaryInteraction(e)}
                  type="button"
                  size="auto"
                  variant="action-link"
                >
                  {cancelButtonText}
                </AppButton>
              );
            case DrawerButtonTypes.POPUP:
              return (
                <AppButton
                  onClick={e => secondaryOnClick?.()}
                  onKeyDown={e => secondaryOnClick?.()}
                  type="button"
                  size="auto"
                  variant="action-link"
                >
                  {cancelButtonText}
                </AppButton>
              );
            default:
              return (
                <AppButton
                  onClick={e => toggleDrawer(e, false)}
                  onKeyDown={e => toggleDrawer(e, false)}
                  type="button"
                  size="auto"
                  variant="action-link"
                >
                  {cancelButtonText}
                </AppButton>
              );
          }
        })()}
      </Box>
      {middleFooter ? middleFooter : <></>}
      <Box
        role="button"
        aria-label="drawer-action-button"
        sx={{ display: actionButtonText ? 'flex' : 'none' }}
      >
        {(() => {
          switch (actionButtonType) {
            case DrawerButtonTypes.BUTTON:
              return (
                <AppButton
                  id={buttonId}
                  type="button"
                  size={matchesMinWidth1024 ? 'md' : 'xl'}
                  variant="primary"
                  onClick={onClickCallback}
                  onKeyDown={onClickCallback}
                  disabled={primaryBtnDisabled || actionButtonDisabled}
                >
                  {actionButtonText}
                </AppButton>
              );
            case DrawerButtonTypes.SUBMIT:
              return (
                <AppButton
                  id="saveButton"
                  type="submit"
                  size={matchesMinWidth1024 ? 'md' : 'xl'}
                  variant="primary"
                  onClick={primaryOnClick?.(onSubmit, onError)}
                  onKeyDown={primaryOnClick?.(onSubmit, onError)}
                  disabled={primaryBtnDisabled || actionButtonDisabled}
                >
                  {actionButtonText}
                </AppButton>
              );
            case DrawerButtonTypes.PDF:
              return (
                <GeneratePDF
                  pdfRef={pdfRef}
                  actionButtonText={actionButtonText}
                  screenSize={matchesMinWidth1024}
                  setLoaderActive={event => onPdFDownloadComplete(event)}
                />
              );
            case DrawerButtonTypes.CUSTOM:
              return (
                <Button
                  type="button"
                  size={matchesMinWidth1024 ? 'small' : 'large'}
                  variant="primary"
                  onClick={onClickCallback}
                  disabled={primaryBtnDisabled || actionButtonDisabled}
                  sx={{ margin: '6px 0px', ...actionButtonStyles }}
                >
                  {actionButtonText}
                </Button>
              );
          }
        })()}
      </Box>
    </Box>
  );

  const handleClose = (e: React.MouseEvent) => {
    if (drawerCloseValidation) drawerCloseValidation();
    else toggleDrawer(e, false);
  };

  return (
    <Drawer
      id={'drawer-panel-' + contentName}
      anchor={anchor}
      open={open}
      onClose={(e: React.MouseEvent) => handleClose(e)}
      sx={{
        '& .MuiDrawer-paper': {
          top: { md: '66px' },
          height: { md: 'calc(100% - 66px)' },
        },
        '& .MuiDrawer-root': {
          top: { md: '66px' },
        },
        '& .MuiModal-backdrop': {
          top: { md: '66px' },
        },
        ...drawerContainerStyles,
      }}
    >
      <EndScreenShareButton showInDesktop={false} />
      {(isSubmitLoaderActive || isPDFLoading) && <AppCircularProgress />}
      <Box
        className={DrawerClasses.layout}
        sx={{ ...innerContainerStyles, position: 'relative' }}
        role="presentation"
      >
        {contentName &&
          (headerType === DrawerHeaderType.Close
            ? DrawerCloseHeader
            : DrawerBackHeader)}
        {alertId && (
          <AppAlertbar
            sx={{
              height: 'fit-content',
              width: 'auto',
              '& .MuiSnackbarContent-root': {
                position: 'absolute',
                left: '12px',
                top: '84px',
                right: '12px',
                boxShadow: 'none',
              },
              maxWidth: '426px',
            }}
            id={alertId}
            refId={alertId}
          />
        )}
        {DrawerContent}
        {showFooter && DrawerButtonFooter}
      </Box>
    </Drawer>
  );
};
