import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  AssignmentSummaryItem,
  ExpenseTypeItem,
} from 'interfaces/Assignment/AssignmentSummaryItem';
import { IReimbursementResponse, IReimbursementTypeRequest } from 'interfaces/Reimbursement/IReimbursement';
import { IContact } from 'interfaces/User/Contact/IContact';
import { IReimbursementState } from './reimbursementState';

const initialState: IReimbursementState = {
  assignments: [],
  expenseTypeItem: {
    startDate: '',
    endDate: '',
    shiftTypes: [],
  },
  reimbursements: {
    items: [],
    paging: {
      limit: '0',
      offset: '0',
      total: '0',
    },
  },
  isLoading: true,
  userContact: {
    contactImage: {
      file: '',
      url: '',
      date: new Date(),
    },
    emailAddress: '',
    employeeId: 0,
    employeeRole: '',
    employeeRoleId: 0,
    firstName: '',
    lastName: '',
    phoneNumber: '',
    smsNumber: '',
    workDayId: 0,
  },
  reimbursementSubmitted: false,
  mileage: 0,
};

const reimbursementSlice = createSlice({
  name: 'reimbursementSlice',
  initialState,
  reducers: {
    getAssignments(state, action) {
      state.isLoading = true;
    },
    setAssignments(state, action: PayloadAction<AssignmentSummaryItem[]>) {
      state.assignments = action.payload;
      state.isLoading = false;
    },
    getExpenseTypes(state, action: PayloadAction<IReimbursementTypeRequest>) {
      state.isLoading = true;
    },
    setExpenseTypes(state, action: PayloadAction<ExpenseTypeItem>) {
      state.expenseTypeItem = action.payload;
      state.isLoading = false;
    },
    getReimbursements(state, action) {
      state.isLoading = true;
    },
    setReimbursements(state, action: PayloadAction<IReimbursementResponse>) {
      state.reimbursements = action.payload;
      state.isLoading = false;
    },
    getUserContact(state, action) {
      state.isLoading = true;
    },
    setUserContact(state, action: PayloadAction<IContact>) {
      state.userContact = action.payload;
      state.isLoading = false;
    },
    setReimbursementSubmission(state, action: PayloadAction<boolean>) {
      state.reimbursementSubmitted = action.payload;
    },
    postJobReqsAction(state, action) {},
    postLocumsGenericAction(state, action) {},
    uploadReimbursementDoc(state, action) {},
    postAirlineTravelAction(state, action) {},
    postAutomotiveTravelAction(state, action) {},
    postBaggageRequirements(state, action) {},
    postMileageRequirements(state, action) {},
    calculateMileageReimbursement(state, action) {},
    setCalculatedMileage(state, action: PayloadAction<{ mileage: number }>) {
      state.mileage = action.payload.mileage;
    },
  },
});

export const {
  setAssignments,
  getAssignments,
  getExpenseTypes,
  setExpenseTypes,
  setReimbursements,
  getReimbursements,
  getUserContact,
  setUserContact,
  postJobReqsAction,
  postLocumsGenericAction,
  uploadReimbursementDoc,
  setReimbursementSubmission,
  postAirlineTravelAction,
  postAutomotiveTravelAction,
  postBaggageRequirements,
  postMileageRequirements,
  calculateMileageReimbursement,
  setCalculatedMileage,
} = reimbursementSlice.actions;

export default reimbursementSlice.reducer;
