export enum FeatureToggles {
  FEMA = 'FEMA',
  LOCAL_PER_DIEM = 'LocalPerDiem',
  RESTRICT_LEAD_PREFERENCES = 'RestrictLeadPreferences',
  MATCH_API_JOB_MATCH = 'MatchApiJobMatch',
  ONE_TRUST_CONSENT_PREFERENCES = 'OneTrustConsentPreferences',
  AIRSHIP_IN_APP_NOTIFICATIONS = 'AirshipInAppNotifications',
  VIRTUAL_INTERVIEW = 'VirtualInterview',
  TIME_AND_PAY_AVAILABLE = 'TimeAndPayAvailable',
  LOG_CREDENTIAL_CENTER_DEFAULT_ACTION = 'LogCredentialCenterDefaultAction',
  ENABLE_TIMESHEETS_FOR_STRIKE = 'EnableTimesheetsForStrike',
  ACTION_ITEM_UPDATE = 'ActionItemUpdate',
  ENABLE_SUPPORT_CENTER = 'EnableSupportCenter',
  IMPACT_TRACKER = 'ImpactTracker',
  UGC = 'UserGeneratedContent',
  ENABLE_COMMUNITY_LIKE_FEATURE = 'EnableCommunityLikeFeature',
  ENABLE_COMMUNITY_SHARE_FEATURE = 'EnableCommunityShareFeature',
  ENABLE_TIMESHEET_INSTRUCTIONS = 'EnableTimesheetInstructions',
  ENABLE_WISELY_PAYCARD = 'EnableStrikeWiselyPayCardActionItem',
  ENABLE_ACS_JOB_SEARCH = 'EnableAcsJobSearchV3',
  ENABLE_SCREEN_SHARE = 'EnableScreenShare',
  ENABLE_SAVED_SEARCH = 'EnableSavedSearchUI',
  ENABLE_SAVED_SEARCH_NOTIFICATION = 'EnableSavedSearchJobsMatchNotifications',
  ENABLE_NEW_JOB_DETAILS_PAGE = 'EnableNewJobDetailsPageUI',
  ENABLE_SAVED_SEARCH_CAROUSEL = 'EnableSavedSearchCarouselUI',
  ENABLE_LOCUMS_TIME_PAY_REIMBURSEMENTS = 'EnableLocumsTimePayReimbursements',
  ENABLE_STRIKE_SCHEDULED_SHIFTS = 'EnableStrikeScheduledShifts',
  ENABLE_GENESYS_CHAT = 'EnableGenesysChatUI',
  ENABLE_RECRUITER_JOB_PICKS = 'EnableGetRecruiterJobPicks',
  ENABLE_ASSIGNMENT_PROFILE_TASKS = 'DisplayProfileTasksInAssignmentFilter',
}
