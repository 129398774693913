import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { cognitiveJobActions } from 'store/slices/jobs/cognitiveJobSearch/cognitiveJobSlice';
import { useHistory } from 'react-router-dom';
import { AppRouteNames } from 'routes/appRouteNames';
import { Box, Grid } from '@mui/material';
import ProfileLink from 'components/Profile/ProfileLink';
import { ArrowLeftIcon } from 'assets/icons/Arrows/Arrow-Left';
import { theme } from 'styles/theme';
import { createDetailFeatureStyles } from 'components/common/PageLayout/PageLayout.styles';
import { useSmMediaQuery } from 'containers/MobileDesktopContainer/useMediaQuery';
import { RecommendedJobCardScroll } from './RecommendedJobCardScroll';

const RecommendedJobsPage = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const classes = createDetailFeatureStyles();
  const isMobileOrTabletDevice = useSmMediaQuery();
  const scrollToRef = useRef<HTMLDivElement>(null);
  const recommendedJobs = useAppSelector(
    state => state.jobsV2?.recommendedJobs,
  );

  const handleBackToSearch = useCallback(() => {
    history.push(`/${AppRouteNames.HOME}`);
  }, [history]);

  const subHeaderBarContent = useMemo(() => {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <ProfileLink
          id="back-to-search"
          sx={{ paddingLeft: '28px' }}
          underline={'none'}
          leftIcon={
            <ArrowLeftIcon
              sx={{
                width: '17px',
                height: '17px',
                color: theme.palette.system.skyBlue,
              }}
            />
          }
          onClick={handleBackToSearch}
        >
          Back
        </ProfileLink>
      </Box>
    );
  }, [handleBackToSearch]);

  useEffect(() => {
    dispatch(cognitiveJobActions.fetchRecommendedJobs(40));

    // scrolling to top on initial screen load
    scrollToRef.current?.scrollIntoView();
  }, []);

  return (
    <Box
      ref={scrollToRef}
      id="recommended-jobs"
      sx={{ backgroundColor: theme?.palette?.common?.white }}
    >
      {subHeaderBarContent && !isMobileOrTabletDevice && (
        <Grid
          container
          className={classes?.subHeaderBar}
          sx={{
            borderBottom: `1px solid ${theme.palette.system.border}`,
            height: '60px',
            backgroundColor: 'system.white',
          }}
        >
          {subHeaderBarContent}
        </Grid>
      )}
      <Box pb={10} pt={{ xs: 4, md: 0 }} px={4} maxWidth={824} mx="auto">
        {/* {Commented this for MVP release} */}
        {/* <CognitiveJobCardScroll
          jobs={recommendedJobs}
          handleResetCurrentFilter={handleResetCurrentFilter}
          isLeadUser={isLeadUser}
          setDrawerOpen={false}
        /> */}
        <RecommendedJobCardScroll
          jobs={recommendedJobs}
          showShortcut={false}
          showCount={false}
        />
      </Box>
    </Box>
  );
};

export default RecommendedJobsPage;
