import { PayloadAction } from '@reduxjs/toolkit/dist/createAction';
import { TOAST_MESSAGE } from 'constants/helperText';
import { JobSearchTab } from 'enums/JobSearchTab';
import { StatusCode } from 'enums/StatusCode';
import { JobSearchSuggestion } from 'enums/jobSearchSuggestion';
import { JobSortType } from 'enums/jobSortType';
import { jobSortTypeText } from 'enums/jobSortTypeText';
import { modifyInitFilterWithDefaultFilter } from 'helpers/jobSearchHelper';
import { IErrorResponse } from 'interfaces/Common/IErrorResponse';
import { IACSFilter } from 'interfaces/Jobs/IACSFilter';
import { IACSJobDetails } from 'interfaces/Jobs/IACSJobDetails';
import { IACSJobRequest } from 'interfaces/Jobs/IACSJobRequest';
import { IACSJobSuggestionRequest } from 'interfaces/Jobs/IACSJobSuggestionRequest';
import { IJobSuggestion } from 'interfaces/Jobs/IACSJobSuggestionResponse';
import { IJobDetailRequest } from 'interfaces/Jobs/IJobDetailRequest';
import { IJobRequest } from 'interfaces/Jobs/IJobsRequest';
import { ISavedSearchRequest } from 'interfaces/Jobs/ISavedSearch';
import { ISavedSearchJobDetails } from 'interfaces/Jobs/ISavedSearchJobDetails';
import { ISavedSearchTopJobRequest } from 'interfaces/Jobs/ISavedSearchTopJobRequest';
import { trackPromise } from 'react-promise-tracker';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import * as service from 'services/jobs/jobService';
import { logger, trackEvent } from 'services/logging/appInsights';
import * as userService from 'services/user/userService';
import { RootState } from 'store/configureStore';
import { openAlert } from 'store/slices/alertbar/alertbarSlice';
import { setError } from 'store/slices/error/errorSlice';
import { cognitiveJobActions } from 'store/slices/jobs/cognitiveJobSearch/cognitiveJobSlice';
import { ICognitiveJobState } from 'store/slices/jobs/cognitiveJobSearch/cognitiveJobState';
import { retrieveUserPreference } from 'store/slices/user/userPreferenceSlice';

const trackedJobsData = (fn, ...args) =>
  trackPromise(fn(...args), 'get-jobs-cognitive');

const trackedJobsSuggestionData = (fn, ...args) =>
  trackPromise(fn(...args), 'get-jobs-suggestion-cognitive');

const userId = (state: RootState) => state.auth.userId;
const fetchedJobs = (state: RootState) => state.jobsV2;

const disciplinesAndSpecialties = (state: RootState) =>
  state.userProfile.disciplinesAndSpecialties;

const filtersForAppInsights = (filters: IACSFilter[]) => {
  let result = [];
  if (!filters || filters.length === 0) return result;

  let selectedFilters = filters.filter(filter => {
    return filter.values.length > 0;
  });
  if (selectedFilters && selectedFilters.length > 0) {
    result = (selectedFilters as any[]).reduce((acc, filter) => {
      let filterArray = [] as string[];
      filter.values.forEach(value => {
        filterArray.push(value.displayName);
      });
      acc.push({
        FilterType: filter.filterType,
        FilterValue: filterArray,
      });
      return acc;
    }, []);
  }
  return result;
};

const mapInsightsObject = (request: IACSJobRequest) => {
  let insightsObject = {
    keyword: request.keywordSearch || '',
    location: request.locationSearch || '',
    filters: filtersForAppInsights(request.filterTypes || []),
    sortOrder: request.orderBy || '',
  };

  return insightsObject;
};

function* fetchACSJobsSaga(action: PayloadAction<IACSJobRequest>) {
  let currentUserId = yield select(userId);

  const emptyResponse: ICognitiveJobState = {
    selectedJobDetails: {} as IACSJobDetails,
    jobCount: 0,
    jobs: [],
    filters: [],
    isLoading: false,
    defaultFilterSet: false,
  };

  if (!currentUserId) return;

  if (!action?.payload?.userId) {
    action.payload.userId = currentUserId;
  }

  yield put(cognitiveJobActions.setRequestFilter(action?.payload));
  try {
    const response = yield call(
      trackedJobsData,
      service.fetchJobsV3,
      action.payload,
    );

    const { data, status } = response;

    trackEvent('NewJobSearchEvent', mapInsightsObject(action?.payload));
    switch (status) {
      case StatusCode.NoContent:
        yield put(cognitiveJobActions.setJobsACS(emptyResponse));
        break;
      case StatusCode.OK:
        let jobs = data;
        if (+(action?.payload?.pageNumber ?? '0') > 1) {
          yield put(
            cognitiveJobActions.setJobsACS({
              ...jobs,
              requestFilter: action?.payload,
            }),
          );
        } else {
          yield put(cognitiveJobActions.setScrollPosition(undefined));
          yield put(
            cognitiveJobActions.setJobsACS({
              ...jobs,
              requestFilter: action?.payload,
            }),
          );
          yield put(cognitiveJobActions.setScrollPosition(0));
        }
        break;
      default:
        yield put(cognitiveJobActions.setJobsACS(emptyResponse));
    }
  } catch (error: any) {
    let err = error.data as IErrorResponse;
    err.errorMessage = TOAST_MESSAGE.SomethingWentWrongTryReloading;
    yield put(setError(err));
    yield put(
      openAlert({
        variant: 'error',
        message: TOAST_MESSAGE.SomethingWentWrongTryReloading,
      }),
    );
    logger.error(error, 'fetchACSJobsSaga', 'cognitiveJobSaga.ts');
  }
}

function* fetchSavedSearchJobsSaga(
  action: PayloadAction<ISavedSearchTopJobRequest[]>,
) {
  try {
    const results: ISavedSearchJobDetails[] = [];

    const payload = action.payload;
    for (let index = 0; index < payload.length; index++) {
      const element = payload[index];

      const response = yield call(
        trackedJobsData,
        service.fetchJobsV3,
        element.request,
      );

      const { data, status } = response;

      trackEvent('NewJobSearchEvent', mapInsightsObject(element.request));
      switch (status) {
        case StatusCode.NoContent:
          results.push({
            savedSearchID: element?.saveSearchId ?? '',
            jobDetails: [],
          });
          break;
        case StatusCode.OK:
          let jobResult = data;
          results.push({
            savedSearchID: element?.saveSearchId ?? '',
            jobDetails: jobResult?.jobs,
          });
          break;
      }
    }

    yield put(cognitiveJobActions.setTopSavedSearchJobsACS(results));
  } catch (error: any) {
    let err = error.data as IErrorResponse;
    err.errorMessage = TOAST_MESSAGE.SomethingWentWrongTryReloading;
    yield put(setError(err));
    yield put(
      openAlert({
        variant: 'error',
        message: TOAST_MESSAGE.SomethingWentWrongTryReloading,
      }),
    );
    logger.error(error, 'fetchACSJobsSaga', 'cognitiveJobSaga.ts');
  }
}

function* fetchSelectedJobDetails(action: PayloadAction<number | undefined>) {
  yield put(cognitiveJobActions.setLoadingState(true));
  if (action.payload === undefined) {
    yield put(cognitiveJobActions.resetSelectedJobDetails());
    yield put(cognitiveJobActions.setLoadingState(false));
    return;
  }

  let currentUserId = yield select(userId);
  let jobsRequest = {} as IJobDetailRequest;
  jobsRequest.userId = currentUserId;
  jobsRequest.jobId = action.payload;

  try {
    const { data } = yield call(service.fetchJobDetailsV2, jobsRequest);
    if (data) {
      yield put(cognitiveJobActions.setSelectedJobDetails(data));
    } else {
      yield put(cognitiveJobActions.resetSelectedJobDetails());
    }
  } catch (e) {
    yield put(
      openAlert({
        variant: 'error',
        message: TOAST_MESSAGE.SomethingWentWrongTryReloading,
      }),
    );
    logger.error(e, 'fetchSelectedJobDetails', 'cognitiveJobSaga.ts');
  } finally {
    yield put(cognitiveJobActions.setLoadingState(false));
  }
}

function* fetchJobSuggestions(action: PayloadAction<IACSJobSuggestionRequest>) {
  let currentUserId = yield select(userId);

  try {
    const { data } = yield call(
      trackedJobsSuggestionData,
      service.fetchJobSuggestions,
      currentUserId,
      action.payload,
      true,
    );

    if (data) {
      switch (action.payload.suggestionType) {
        case JobSearchSuggestion.LOCATION:
          yield put(
            cognitiveJobActions.setSuggestedLocations(data.suggestions ?? []),
          );
          break;
        case JobSearchSuggestion.SKILLSET:
          yield put(cognitiveJobActions.setSuggestedSkills(data));
          break;
      }
    }
  } catch (e) {
    logger.error(e, 'fetchJobSuggestions', 'cognitiveJobSaga.ts');
  }
}

function* fetchDefaultFilterAction() {
  let currentUserId = yield select(userId);
  let disciplinesAndSpecialtiesList = yield select(disciplinesAndSpecialties);
  let requests: IACSJobSuggestionRequest[] = [];

  if (
    !disciplinesAndSpecialtiesList ||
    disciplinesAndSpecialtiesList.length === 0
  ) {
    try {
      const { data } = yield call(userService.getUserProfile, currentUserId);
      switch (data?.statusCode) {
        case StatusCode.OK:
          disciplinesAndSpecialtiesList = data.disciplinesAndSpecialties;
          if (
            disciplinesAndSpecialtiesList &&
            disciplinesAndSpecialtiesList.length > 0
          ) {
            disciplinesAndSpecialtiesList.forEach(element => {
              requests.push({
                sourceDisciplineID: element.disciplineId.toString(),
                sourceSpecialtyID: element.specialtyId.toString(),
              });
            });
          }
          break;
        default:
          disciplinesAndSpecialtiesList = [];
      }
    } catch (e) {
      disciplinesAndSpecialtiesList = [];
      logger.error(e, 'fetchDefaultFilterAction', 'cognitiveJobSaga.ts');
    }
  } else {
    if (
      disciplinesAndSpecialtiesList &&
      disciplinesAndSpecialtiesList.length > 0
    ) {
      disciplinesAndSpecialtiesList.forEach(element => {
        requests.push({
          sourceDisciplineID: element.disciplineId.toString(),
          sourceSpecialtyID: element.specialtyId.toString(),
        });
      });
    }
  }

  try {
    let jobSuggestionList: IJobSuggestion[] = [];
    for (let index = 0; index < requests.length; index++) {
      const { data } = yield call(
        trackedJobsSuggestionData,
        service.fetchJobSuggestions,
        currentUserId,
        requests[index],
      );

      if (data) {
        const suggestions =
          data?.communitySuggestions?.map(x => x?.jobSuggestions)?.flat() ?? [];

        jobSuggestionList.push(...suggestions);
      }
    }

    const orderBy = jobSortTypeText.MAX_PAY_RATE;

    let prefilter: any[] = modifyInitFilterWithDefaultFilter(jobSuggestionList);

    const request: IACSJobRequest = {
      filterTypes: prefilter,
      pageSize: '40',
      pageNumber: '1',
      orderBy: orderBy as jobSortTypeText,
    };
    yield put(cognitiveJobActions.setDefaultFilter(jobSuggestionList));
    yield put(cognitiveJobActions.fetchJobs(request));
    yield put(cognitiveJobActions.setScrollToTop(true));
  } catch (e) {
    logger.error(e, 'fetchDefaultFilterAction', 'cognitiveJobSaga.ts');
  }
}

export function* fetchRecommendedJobsSaga(action) {
  let currentUserId = yield select(userId);
  if (!currentUserId) return;

  // limit to be refactored once pagination issue is fixed from backend for recommended jobs
  const jobSearchRequest: IJobRequest = {
    limit: action.payload,
    sort: JobSortType.Recommended,
    userId: currentUserId,
    isFavorite: false,
  };

  try {
    const response = yield call(
      trackedJobsData,
      service.fetchJobs,
      jobSearchRequest,
    );

    // Trigger anytime we get jobs on the jobs page
    trackEvent('JobSearchExecuted');

    const { data, status } = response;

    switch (status) {
      case StatusCode.NoContent:
        yield put(cognitiveJobActions.setRecommendedJobs([]));

        break;
      case StatusCode.OK:
        {
          /* {Commented this for MVP release} */
        }
        // let jobs = [] as IACSJobDetails[];
        // for (const item of data.items) {
        //   let jobsRequest = {} as IJobDetailRequest;
        //   jobsRequest.userId = currentUserId;
        //   jobsRequest.jobId = item.jobId;
        //   const job = yield call(service.fetchJobDetailsV2, jobsRequest);
        // if (job.status === StatusCode.OK) {
        //     jobs.push(job.data);
        //   }
        //   yield put(
        //     cognitiveJobActions.setRecommendedJobs([...jobs.slice(0, 40)] ),
        //   );
        // }
        // showing first 40 jobs, slice to be removed once api fix is ready
        yield put(
          cognitiveJobActions.setRecommendedJobs(data?.items?.slice(0, 40)),
        );
        break;
      default:
        yield put(cognitiveJobActions.setRecommendedJobs([]));
    }
  } catch (e) {
    logger.error(e, 'fetchRecommendedJobsSaga', 'cognitiveJobSaga.ts');
  }
}

export function* postSavedSearchSaga(
  action: PayloadAction<ISavedSearchRequest>,
) {
  try {
    let currentUserId = yield select(userId);
    const response = yield call(
      service.postSavedSearch,
      currentUserId,
      action.payload.savedSearch,
    );
    if (response.status === StatusCode.OK) {
      yield put(retrieveUserPreference());
      yield put(
        openAlert({
          variant: 'success',
          message: action.payload.toastMessage,
        }),
      );
      if (action.payload.toastMessage === TOAST_MESSAGE.SearchSaved) {
        yield put(
          cognitiveJobActions.setActiveSavedSearch(
            action.payload.savedSearch[0],
          ),
        );
        yield put(
          cognitiveJobActions.setActiveJobSearchTab(JobSearchTab.SavedSearches),
        );
      }
    }
  } catch (e: any) {
    yield put(
      openAlert({
        variant: 'error',
        message: e?.data?.errorMessage,
      }),
    );
    logger.error(e, 'postSavedSearchSaga', 'cognitiveJobSaga.ts');
  }
}

export function* getRecruiterPicks() {
  try {
    let currentUserId = yield select(userId);
    const response = yield call(service.fetchRecruiterPicks, currentUserId);
    const { data, status } = response;
    switch (status) {
      case StatusCode.NoContent:
        yield put(cognitiveJobActions.setRrecruiterPicksJobs(undefined));
        break;
      case StatusCode.OK:
        yield put(cognitiveJobActions.setRrecruiterPicksJobs(data));
        break;
      default:
        yield put(cognitiveJobActions.setRrecruiterPicksJobs(undefined));
    }
  } catch (e: any) {
    logger.error(e, 'fetchRecruiterPicksJobsSaga', 'cognitiveJobSaga.ts');
  }
}

export function* cognitiveJobSaga() {
  yield all([
    takeLatest(cognitiveJobActions.fetchJobs.type, fetchACSJobsSaga),
    takeLatest(
      cognitiveJobActions.fetchSavedSearchJobs.type,
      fetchSavedSearchJobsSaga,
    ),
    takeLatest(
      cognitiveJobActions.getJobDetailsAction.type,
      fetchSelectedJobDetails,
    ),
    takeLatest(
      cognitiveJobActions.fetchJobSuggestionsAction.type,
      fetchJobSuggestions,
    ),
    takeLatest(
      cognitiveJobActions.fetchDefaultFilterAction.type,
      fetchDefaultFilterAction,
    ),
    takeLatest(
      cognitiveJobActions.fetchRecommendedJobs.type,
      fetchRecommendedJobsSaga,
    ),
    takeLatest(cognitiveJobActions.postSavedSearch.type, postSavedSearchSaga),
    takeLatest(cognitiveJobActions.getRecruiterPicks.type, getRecruiterPicks),
  ]);
}
