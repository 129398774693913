import { PayloadAction } from '@reduxjs/toolkit';
import { mockArbitrationActionItem } from '__mocks__/mockArbitrationActionItem';
import PdfFileDownloader from 'components/common/PDF/PdfFileDownloader';
import { TOAST_MESSAGE } from 'constants/helperText';
import { CredentialSubType } from 'enums/credential';
import { DataLayerFormType } from 'enums/dataLayer';
import { EmployeeRoleId } from 'enums/employeeRoleId';
import { StatusCode } from 'enums/StatusCode';
import { formatActionSurveyContracts } from 'helpers/credentialCenterHelper';
import { httpSuccess } from 'helpers/serviceHelper';
import { IErrorAuthResponse } from 'interfaces/Common/ICommonContract';
import {
  IErrorResponse,
  IErrorResponseData,
} from 'interfaces/Common/IErrorResponse';
import { IContact } from 'interfaces/User/Contact/IContact';
import { trackPromise } from 'react-promise-tracker';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { getAssignmentDetails } from 'services/assignments/assignmentService';
import {
  fetchCredentialData,
  fetchDocEnvUrl,
  fetchFormStackData,
  fetchUserSubmissions,
  getAssignmentCredentialSubmissions,
  getCredentialDetailDocument,
  getCredentialDocumentsList,
  refreshCredentialData,
  saveCredentialChecklist as saveCredentialChecklistSvc,
  saveLicenseData,
  submitCredentials,
  updateCredentialStatus,
  uploadCredentialDocumentV2,
  uploadDocumentV2,
} from 'services/credentials/credentialService';
import { fetchPdf, getSecureImage } from 'services/domain/domainService';
import { logger, trackEvent } from 'services/logging/appInsights';
import { RootState } from 'store/configureStore';
import { actionItemActions } from 'store/slices/actionItem/actionItemSlice';

import { openAlert } from 'store/slices/alertbar/alertbarSlice';
import { credentialActions } from 'store/slices/credentials/credentialSlice';
import {
  ICredentialChecklistRequest,
  ICredentialChecklistState,
  ICredentialStatus,
  ILicenseVerificationRequest,
} from 'store/slices/credentials/credentialState';
import { dataLayerActions } from 'store/slices/dataLayer/dataLayerSlice';
import { fileUploadsActions } from 'store/slices/fileUpload/fileUploadSlice';
import {
  IDocumentUpload,
  IUploadedFileState,
} from 'store/slices/fileUpload/fileUploadState';
import { downloadBase64 } from 'utils/helper';

const userIdSelector = (state: RootState) => state.auth.userId;
const uploadedFilesSelector = (state: RootState) =>
  state.fileUpload.uploadedFiles;
export const submissionSelector = (state: RootState) =>
  state.credential.submissions;

const selectedClearanceItem = (state: RootState) =>
  state.actionItem.selectedClearanceItem;

const TrackGetCredentialData = (fn, ...args) =>
  trackPromise(fn(...args), 'get-credential-data');

/**
 *  Watcher function to fetch credential detail by user id
 *
 */
function* getCredentialData(action: PayloadAction<boolean>) {
  try {
    const userId = yield select(userIdSelector);
    const credentialData = yield call(
      TrackGetCredentialData,
      action.payload ? refreshCredentialData : fetchCredentialData,
      userId,
    );
    const { data } = credentialData;
    yield put(
      credentialActions.setCredentialDetail(data?.userCredentials || []),
    );
    const formattedData = formatActionSurveyContracts(
      data?.openRequirementsAll || [],
    );
    /*Todo: this is a mock data - Remove on api Integration*/
    const arbitraryMockData = mockArbitrationActionItem;
    const showMockForSelected = userId === '22042285';
    const updatedData = showMockForSelected
      ? [...formattedData, arbitraryMockData]
      : formattedData;
    yield put(credentialActions.setOpenRequirements(updatedData) || []);
    yield put(
      credentialActions.setOpenRequirementsUnique(data?.openRequirements) || [],
    );
  } catch (error: any) {
    let err: IErrorAuthResponse | any = {};
    err.errorMessage = TOAST_MESSAGE.SomethingWentWrongTryReloading;
    yield put(credentialActions.setError(err));
    logger.error(error, 'getCredentialData', 'credentialSaga.ts');
  }
}

export function* getCredentialChecklistData(action: PayloadAction<number>) {
  if (!!action.payload) {
    const userId = yield select(userIdSelector);
    const response = yield call(() =>
      getAssignmentDetails(userId, action.payload),
    );

    if (response && httpSuccess(response?.status)) {
      const { items } = response?.data;
      if (items && items[0]) {
        const creds = items[0].credentials;
        const contact = items[0].contacts.find(
          (c: IContact) =>
            c.employeeRoleId === EmployeeRoleId.CREDENTIALING_ANALYST,
        );
        if (contact && (contact || {})?.contactImage) {
          let imageUrl = yield getSecureImage(
            `${contact?.contactImage?.url}/url?width=128`,
          );
          contact.contactImage = imageUrl.data.fileUrl;
        }
        yield put(
          credentialActions.setCredentialChecklist({
            list: creds,
            helpContact: contact,
            facilityName: items[0].facilityName,
            credentialCloseDate: items[0].credentialCloseDate,
            placementId: items[0].placementId,
          } as ICredentialChecklistState),
        );
      }
    }
  } else {
    yield put(
      openAlert({
        variant: 'error',
        message: TOAST_MESSAGE.SomethingWentWrongTryAgain,
      }),
    );
    logger.error(
      'Missing Placement Id',
      'getCredentialChecklistData',
      'credentialSaga.ts',
    );
  }
}

export function* getCredentialChecklistFromCredCenterData(
  action: PayloadAction<any>,
) {
  if (!!action.payload) {
    const userId = yield select(userIdSelector);
    const response = yield call(() =>
      getAssignmentDetails(userId, action.payload),
    );

    if (response && httpSuccess(response?.status)) {
      const { items } = response?.data;
      if (items && items[0]) {
        const creds = items[0].credentials;
        yield put(
          credentialActions.setCredentialChecklist({
            list: creds,
            facilityName: items[0].facilityName,
            credentialCloseDate: items[0].credentialCloseDate,
            placementId: items[0].placementId,
          } as ICredentialChecklistState),
        );
      }
    } else {
      // error handling
      yield put(credentialActions.setSelectedChecklistItem(undefined));
    }
  } else {
    yield put(
      openAlert({
        variant: 'error',
        message: TOAST_MESSAGE.SomethingWentWrongTryAgain,
      }),
    );
    logger.error(
      'Missing Placement Id',
      'getCredentialChecklistData',
      'credentialSaga.ts',
    );
  }
}

export function* deleteCredential(action: PayloadAction<IUploadedFileState>) {
  try {
    yield put(fileUploadsActions.removeUploadedFile(action.payload));
    // trigger on delete credential document
    trackEvent('DeleteDocument_Success');
  } catch (e: any) {
    logger.error(e, 'deleteCredential', 'credentialSaga.ts');
  }
}

export function* submitCredentialsForApproval(
  action: PayloadAction<ICredentialChecklistRequest>,
) {
  try {
    const userId = yield select(userIdSelector);
    let docUrls = [] as string[];
    const uploadedFiles = yield select(uploadedFilesSelector);
    for (const doc of uploadedFiles) {
      if (
        userId &&
        doc?.fileBase64 &&
        doc?.fileBase64?.fileName &&
        doc?.fileBase64?.content
      ) {
        const uploadResponse = yield call(uploadDocumentV2, {
          userId,
          placementId: action.payload.placementId,
          docFile: { files: [doc.fileBase64] },
        } as any);
        if (uploadResponse && httpSuccess(uploadResponse.status)) {
          docUrls.push(uploadResponse.data?.filePaths[0]);
        }
      }
    }
    if ((userId && docUrls && docUrls.length > 0) || action.payload.comments) {
      const response = yield call(saveCredentialChecklistSvc, {
        userId,
        placementId: action.payload.placementId,
        credential: {
          comments: action.payload.comments,
          credentialIds: action.payload.credentialIds,
          fileNames: docUrls,
        },
      });
      if (response && httpSuccess(response?.status)) {
        const { data } = response;
        if (data?.isSubmitted) {
          yield put(
            openAlert({
              variant: 'success',
              message:
                action?.payload?.isLicense === true
                  ? TOAST_MESSAGE.LicenseSubmitted
                  : TOAST_MESSAGE.CredentialSubmitted,
            }),
          );
        }
        yield put(
          credentialActions.getCredentialChecklist(action.payload.placementId),
        );
        yield put(credentialActions.setSelectedChecklistItem(undefined));
        yield put(credentialActions.getCredentialDetailAction(true));
        yield put(fileUploadsActions.reset());
        yield put(
          dataLayerActions.formCompleteAction(DataLayerFormType.ADD_CREDENTIAL),
        );
        yield put(credentialActions.getAssignmentCredentialSubmission());
      } else {
        yield put(
          openAlert({
            variant: 'error',
            message: TOAST_MESSAGE.SomethingWentWrongTryAgain,
          }),
        );
      }
    } else {
      yield put(
        openAlert({
          variant: 'error',
          message: TOAST_MESSAGE.SomethingWentWrongTryAgain,
        }),
      );
    }
  } catch (e: any) {
    logger.error(e, 'submitCredentialsForApproval', 'credentialSaga.ts');
  }
}

function* submitIdentification(action: PayloadAction<any>) {
  try {
    const userId = yield select(userIdSelector);
    const docsUploaded = yield call(uploadDocumentV2, {
      userId,
      placementId: action.payload.placementId,
      docFile: action.payload.docFiles,
    } as any);
    if (docsUploaded && httpSuccess(docsUploaded?.status)) {
      const { data } = docsUploaded;
      if (data?.success) {
        const response = yield call(saveCredentialChecklistSvc, {
          userId,
          placementId: action.payload.placementId,
          credential: {
            comments: action.payload.comments,
            credentialIds: action.payload.credentialIds,
            fileNames: data?.filePaths,
          },
        });
        if (response && httpSuccess(response?.status)) {
          const { data } = response;
          if (data?.isSubmitted) {
            yield put(
              openAlert({
                variant: 'success',
                message: TOAST_MESSAGE.IdentificationSubmitted,
              }),
            );
            yield put(
              credentialActions.getCredentialChecklist(
                action.payload.placementId,
              ),
            );
            yield put(credentialActions.setSelectedChecklistItem(undefined));
            yield put(credentialActions.getCredentialDetailAction(true));
            yield put(fileUploadsActions.reset());
            yield put(credentialActions.getAssignmentCredentialSubmission());
          }
        }
      }
      yield put(credentialActions.setDocumentUploadResponse(data));
    } else {
      yield put(
        openAlert({
          variant: 'error',
          message: TOAST_MESSAGE.SomethingWentWrongTryAgain,
        }),
      );
    }
  } catch (e) {
    yield put(
      openAlert({
        variant: 'error',
        message: TOAST_MESSAGE.SomethingWentWrongTryAgain,
      }),
    );
    logger.error(e, 'submitIdentification', 'credentialSaga.ts');
  }
}

export function* submitCredentialsForApprovalFromDrawer(
  action?: PayloadAction<any | undefined>,
) {
  try {
    const userId = yield select(userIdSelector);
    const uploadedFiles = yield select(uploadedFilesSelector);
    const clearanceItem = yield select(selectedClearanceItem);

    let docUrls = [] as string[];
    for (const doc of uploadedFiles) {
      if (userId && doc?.fileBase64) {
        const docUrl = yield call(uploadCredentialDocumentV2, {
          userId,
          docFile: { files: [doc.fileBase64] },
        } as any);
        if (
          docUrl &&
          httpSuccess(docUrl?.status) &&
          docUrl?.data?.filePaths?.[0]
        )
          docUrls.push(docUrl?.data?.filePaths[0]);
      }
    }

    if (userId && docUrls && docUrls.length > 0) {
      const credentialId =
        action?.payload?.credentialSubtype === CredentialSubType.User_Credential
          ? action?.payload?.id
          : undefined;
      const response = yield call(submitCredentials, {
        userId,
        filePaths: docUrls,
        credentialId,
      });
      if (response && httpSuccess(response?.status)) {
        yield put(
          openAlert({
            variant: 'success',
            message:
              action?.payload?.isLicense === true
                ? TOAST_MESSAGE.LicenseSubmitted
                : TOAST_MESSAGE.CredentialSubmitted,
          }),
        );

        if (
          action?.payload?.credentialSubtype === CredentialSubType.Action_Item
        ) {
          yield put(
            actionItemActions.removeActionItem({
              dismissals: { dismissals: [action?.payload?.id] },
            }),
          );
        }

        if (clearanceItem && clearanceItem.id) {
          yield put(actionItemActions.dismissActionItemForClearanceItem());
        }

        yield put(fileUploadsActions.reset());
        yield put(credentialActions.getSubmissions());
        yield put(credentialActions.setSelectedChecklistItem(undefined));
        yield put(credentialActions.getCredentialDetailAction(true));
        yield put(
          dataLayerActions.formCompleteAction(DataLayerFormType.ADD_CREDENTIAL),
        );
      }
    }
  } catch (e: any) {
    logger.error(
      e,
      'submitCredentialsForApprovalFromDrawer',
      'credentialSaga.ts',
    );
  }
}

function* submitCredentialDocumentV2(action: PayloadAction<IDocumentUpload>) {
  try {
    const userId = yield select(userIdSelector);
    const response = yield call(uploadCredentialDocumentV2, {
      userId,
      docFile: action.payload,
    } as any);
    if (response && httpSuccess(response?.status)) {
      const { data } = response;
      if (data?.success) {
        let docUrls = [] as string[];
        for (const fileIndex in data.filePaths) {
          docUrls.push(data.filePaths[fileIndex]);
        }
        const documentSubmitResponse = yield call(submitCredentials, {
          userId,
          filePaths: docUrls,
        });
        if (
          documentSubmitResponse &&
          httpSuccess(documentSubmitResponse?.status)
        ) {
          yield put(
            openAlert({
              variant: 'success',
              message: TOAST_MESSAGE.IdentificationSubmitted,
            }),
          );
          yield put(credentialActions.getAssignmentCredentialSubmission());
        }
      }
      yield put(credentialActions.setDocumentUploadResponse(data));
    } else {
      yield put(
        openAlert({
          variant: 'error',
          message: TOAST_MESSAGE.SomethingWentWrongTryAgain,
        }),
      );
    }
  } catch (e) {
    yield put(
      openAlert({
        variant: 'error',
        message: TOAST_MESSAGE.SomethingWentWrongTryAgain,
      }),
    );
    logger.error(e, 'submitCredentialDocumentV2', 'credentialSaga.ts');
  }
}

function* submitLicenseDataForApproval(
  action: PayloadAction<ILicenseVerificationRequest>,
) {
  try {
    const userId = yield select(userIdSelector);
    const response = yield call(() => saveLicenseData(userId, action.payload));
    if (response && httpSuccess(response?.status)) {
      const { data } = response;
      if (data?.success) {
        yield put(
          openAlert({
            variant: 'success',
            message: TOAST_MESSAGE.LicenseSubmitted,
          }),
        );
        if (!!action.payload.placementId) {
          yield put(
            credentialActions.getCredentialChecklist(
              action.payload.placementId,
            ),
          );
        }
        yield put(credentialActions.setSelectedChecklistItem(undefined));
        yield put(credentialActions.getCredentialDetailAction(true));
        yield put(credentialActions.getAssignmentCredentialSubmission());
        yield put(credentialActions.getSubmissions());
      } else {
        yield put(
          openAlert({
            variant: 'error',
            message: TOAST_MESSAGE.SomethingWentWrongTryAgain,
          }),
        );
      }
    }
  } catch (e) {
    yield put(
      openAlert({
        variant: 'error',
        message: TOAST_MESSAGE.SomethingWentWrongTryAgain,
      }),
    );
    logger.error(e, 'submitLicenseDataForApproval', 'credentialSaga.ts');
  }
}

function* getCredentialDetailDocSaga(action) {
  try {
    const userId = yield select(userIdSelector);
    const response = yield call(() =>
      getCredentialDetailDocument(userId, action.payload),
    );
    const { data } = response;
    if (response.status === StatusCode.NoContent) {
      yield put(credentialActions.setCredentialDetailDoc(data));
    } else if (data) {
      yield put(credentialActions.setCredentialDetailDoc(data[0]));
    }
  } catch (error: any) {
    const err = error.data as IErrorResponseData;
    yield put(
      openAlert({
        variant: 'error',
        message: err?.message,
      }),
    );
    logger.error(error, 'getCredentialDetailDocSaga', 'credentialSaga.ts');
  }
}
function* getCredentialDocumentSaga(action) {
  try {
    const userId = yield select(userIdSelector);
    const response = yield call(() =>
      getCredentialDocumentsList(userId, action.payload),
    );
    const { data } = response;
    if (response.status === 204) {
      yield put(
        openAlert({
          variant: 'error',
          // eslint-disable-next-line quotes
          message: TOAST_MESSAGE.NoAttachment,
        }),
      );
    } else {
      if (data) {
        data.credentials.map(credentialItem =>
          credentialItem.documents.map(item => {
            downloadBase64(item);
          }),
        );
        yield call(credentialActions.setCredentialDocuments, data);
      }
    }
  } catch (error: any) {
    const err = error.data as IErrorResponseData;
    yield put(
      openAlert({
        variant: 'error',
        message: err?.message,
      }),
    );
    logger.error(error, 'getCredentialDocumentSaga', 'credentialSaga.ts');
  }
}
function* getSubmissionsSaga() {
  try {
    const userId = yield select(userIdSelector);
    const response = yield call(fetchUserSubmissions, { userId });
    const { data } = response;
    if (data) {
      yield put(credentialActions.setSubmissions({ ...data }));
    }
  } catch (error: any) {
    const err = error.data as IErrorResponse;
    yield put(credentialActions.setError(err));
    logger.error(error, 'getSubmissionsSaga', 'credentialSaga.ts');
  }
}

export function* loadMoreSubmissionSaga(action) {
  try {
    const userId = yield select(userIdSelector);
    const existingFormSubmission = yield select(submissionSelector);
    const response = yield call(fetchUserSubmissions, {
      userId,
      offset: action.payload.offset,
      limit: action.payload.limit,
    });
    const { data } = response;
    if (data) {
      const existingItems = existingFormSubmission.items;
      const responseItems = data.items;
      const finalResult = [...existingItems, ...responseItems] as any;
      yield put(
        credentialActions.setSubmissions({
          items: finalResult,
          paging: data.paging,
        }),
      );
    }
  } catch (error: any) {
    const err = error.data as IErrorResponse;
    yield put(credentialActions.setError(err));
    logger.error(error, 'loadMoreSubmissionSaga', 'credentialSaga.ts');
  }
}

function* getDocuSignEnvelopeUrl(action) {
  try {
    const userId = yield select(userIdSelector);
    const response = yield call(() => fetchDocEnvUrl(userId, action.payload));
    const { data } = response;
    if (data) {
      yield put(credentialActions.setDocEnvUrl({ ...data }));
    }
  } catch (error: any) {
    const err = error.data as IErrorResponse;
    yield put(credentialActions.setError(err));
    logger.error(error, 'getDocuSignEnvelopeUrl', 'credentialSaga.ts');
  }
}

function* getFormStackDocData(action) {
  try {
    const response = yield call(() => fetchFormStackData(action.payload));
    const { data } = response;
    if (data) {
      yield put(credentialActions.setFormStackData({ ...data }));
    }
  } catch (error: any) {
    const err = error.data as IErrorResponse;
    yield put(credentialActions.setError(err));
    logger.error(error, 'getFormStackDocData', 'credentialSaga.ts');
  }
}

function* setCredentialStatus(action: PayloadAction<ICredentialStatus>) {
  try {
    if (!!action.payload.placementId && !!action.payload.credentialId) {
      yield put(credentialActions.resetOpenRequirementsItemToRemove());
      const userId = yield select(userIdSelector);
      const response = yield call(() =>
        updateCredentialStatus({
          userId,
          placementId: action.payload.placementId,
          credentialId: action.payload.credentialId,
        }),
      );
      if (response && httpSuccess(response?.status)) {
        yield put(credentialActions.getCredentialDetailAction(true));
        yield put(
          credentialActions.getCredentialChecklist(action.payload.placementId),
        );
        yield put(credentialActions.getAssignmentCredentialSubmission());
      }
    } else {
      yield put(
        openAlert({
          variant: 'error',
          message: TOAST_MESSAGE.PlacementOrCredentialMissing,
        }),
      );
    }
  } catch (error: any) {
    const err = error.data as IErrorResponse;
    yield put(credentialActions.setError(err));
    logger.error(error, 'setCredentialStatus', 'credentialSaga.ts');
  }
}

function* fetchPdfData(action: PayloadAction<string>) {
  try {
    const response = yield call(fetchPdf, action.payload);
    PdfFileDownloader(
      response,
      action.payload.substring(66, action.payload.length - 4),
    );
  } catch (error: any) {
    const err = error.data as IErrorResponseData;
    yield put(
      openAlert({
        variant: 'error',
        message: err?.message,
      }),
    );
    logger.error(error, 'fetchPdfData', 'credentialSaga.ts');
  }
}

function* fetchAssignmentCredentialSubmissions() {
  try {
    const userId = yield select(userIdSelector);
    const response = yield call(getAssignmentCredentialSubmissions, { userId });
    const { data } = response;
    if (data) {
      yield put(
        credentialActions.setAssignmentCredentialSubmission([
          ...data.submissions,
        ]),
      );
    }
  } catch (error: any) {
    const err = error.data as IErrorResponse;
    yield put(credentialActions.setError(err));
    logger.error(
      error,
      'fetchAssignmentCredentialSubmissions',
      'credentialSaga.ts',
    );
  }
}

export function* credentialSaga() {
  yield all([
    takeLatest(
      credentialActions.getCredentialDetailAction.type,
      getCredentialData,
    ),
    takeLatest(
      credentialActions.getCredentialChecklist.type,
      getCredentialChecklistData,
    ),
    takeLatest(
      credentialActions.getCredentialChecklistFromCredCenterData.type,
      getCredentialChecklistFromCredCenterData,
    ),
    takeLatest(
      credentialActions.submitCredentialForApprovalAction.type,
      submitCredentialsForApproval,
    ),
    takeLatest(
      credentialActions.submitIdentificationAction.type,
      submitIdentification,
    ),
    takeLatest(
      credentialActions.submitCredentialForApprovalFromDrawerAction.type,
      submitCredentialsForApprovalFromDrawer,
    ),
    takeLatest(credentialActions.deleteCredentialAction.type, deleteCredential),
    takeLatest(
      credentialActions.getCredentialDetailDoc.type,
      getCredentialDetailDocSaga,
    ),
    takeLatest(
      credentialActions.getCredentialDocuments.type,
      getCredentialDocumentSaga,
    ),
    takeLatest(credentialActions.getSubmissions.type, getSubmissionsSaga),
    takeLatest(
      credentialActions.loadMoreSubmissions.type,
      loadMoreSubmissionSaga,
    ),
    takeLatest(credentialActions.getDocEnvUrl.type, getDocuSignEnvelopeUrl),
    takeLatest(
      credentialActions.getFormStackDataAction.type,
      getFormStackDocData,
    ),
    takeLatest(
      credentialActions.setCredentialStatusAction.type,
      setCredentialStatus,
    ),
    takeLatest(credentialActions.getPdfContentAction.type, fetchPdfData),
    takeLatest(
      credentialActions.submitLicenseForApprovalAction.type,
      submitLicenseDataForApproval,
    ),
    takeLatest(
      credentialActions.submitCredentialDocumentAction.type,
      submitCredentialDocumentV2,
    ),
    takeLatest(
      credentialActions.getAssignmentCredentialSubmission.type,
      fetchAssignmentCredentialSubmissions,
    ),
  ]);
}
