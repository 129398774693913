import {
  PayloadAction,
  PrepareAction,
  createAction,
  createSlice,
} from '@reduxjs/toolkit';
import { IACSJobDetails } from 'interfaces/Jobs/IACSJobDetails';
import { IACSJobRequest } from 'interfaces/Jobs/IACSJobRequest';
import { IACSJobSuggestionRequest } from 'interfaces/Jobs/IACSJobSuggestionRequest';
import {
  IACSJobSuggestionResponse,
  IJobSuggestion,
} from 'interfaces/Jobs/IACSJobSuggestionResponse';
import { ICognitiveJobState } from './cognitiveJobState';
import {
  ISavedSearch,
  ISavedSearchRequest,
} from 'interfaces/Jobs/ISavedSearch';
import { ISavedSearchTopJobRequest } from 'interfaces/Jobs/ISavedSearchTopJobRequest';
import { ISavedSearchJobDetails } from 'interfaces/Jobs/ISavedSearchJobDetails';
import { MenuIcons } from 'enums/menuIcons';
import { IACSJobSearchResponse } from 'interfaces/Jobs/IACSJobSearchResponse';

const initialState: ICognitiveJobState = {
  jobCount: 0,
  jobs: [],
  filters: [],
  selectedJobId: undefined,
  selectedJobDetails: {} as IACSJobDetails,
  pageSize: '40',
  requestFilter: {} as IACSJobRequest,
  isLoading: false,
  suggestedLocations: [],
  suggestedSkillset: { communitySuggestions: [] },
  scrollPosition: 0,
  currentSort: undefined,
  defaultFilter: [],
  defaultFilterSet: false,
  recommendedJobs: [] as IACSJobDetails[],
  recruiterPicksJobs: {} as IACSJobSearchResponse,
  scrollToTop: false,
  activeSavedSearch: {} as ISavedSearch,
  activeJobSearchTab: undefined,
  editSavedSearchItem: {} as ISavedSearch,
  sourcePage: undefined,
  scrollToView: '',
};

const fetchJobs = createAction(
  'FETCH_ACS_JOBS',
  function prepare(fetchJobACSRequest: IACSJobRequest) {
    return {
      payload: {
        ...fetchJobACSRequest,
      },
    };
  },
);

const getJobDetailsAction = createAction<number>('GET_JOB_DETAILS');
const fetchJobSuggestionsAction = createAction<
  PrepareAction<IACSJobSuggestionRequest>
>(
  'FETCH_JOB_SUGGESTIONS',
  function prepare(searchRequest: IACSJobSuggestionRequest) {
    return {
      payload: searchRequest,
    };
  },
);

const fetchDefaultFilterAction = createAction<
  PrepareAction<IACSJobSuggestionRequest[]>
>(
  'FETCH_DEFAULT_JOB_FILTER',
  function prepare(defaultFilterRequest: IACSJobSuggestionRequest[]) {
    return {
      payload: defaultFilterRequest,
    };
  },
);

const fetchRecommendedJobs = createAction<number>('FETCH_RECOMMENDED_JOBS');
const fetchSavedSearchJobs = createAction<ISavedSearchTopJobRequest[]>(
  'FETCH_SAVED_SEARCH_JOBS',
);

const postSavedSearch = createAction<ISavedSearchRequest>('POST_SAVED_SEARCH');

const cognitiveJobSlice = createSlice({
  name: 'cognitiveJobs',
  initialState,
  reducers: {
    setJobsACS(state, action: PayloadAction<ICognitiveJobState>) {
      if (
        action.payload?.requestFilter?.pageNumber !== '1' &&
        action.payload?.jobs.length > 0
      ) {
        state.jobs = [...state.jobs, ...action.payload.jobs];
      } else {
        state.jobs = action.payload?.jobs;
      }
      state.jobCount = action.payload?.jobCount;
      state.filters = action.payload?.filters;
    },
    setTopSavedSearchJobsACS(
      state,
      action: PayloadAction<ISavedSearchJobDetails[]>,
    ) {
      if (action?.payload) {
        state.savedSearchTopJobs = [...action.payload];
      }
    },
    resetSelectedJobDetails(state) {
      return {
        ...state,
        selectedJobId: undefined,
        selectedJobDetails: {} as IACSJobDetails,
      };
    },
    setSelectedJobDetails(state, action: PayloadAction<IACSJobDetails>) {
      return {
        ...state,
        selectedJobId: action.payload.jobID,
        selectedJobDetails: action.payload,
      };
    },
    setRequestFilter(state, action: PayloadAction<IACSJobRequest>) {
      return {
        ...state,
        requestFilter: action.payload,
      };
    },
    setLoadingState(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        isLoading: action.payload,
      };
    },
    setSuggestedLocations(state, action: PayloadAction<string[]>) {
      return {
        ...state,
        suggestedLocations: action.payload,
      };
    },
    setSuggestedSkills(
      state,
      action: PayloadAction<IACSJobSuggestionResponse>,
    ) {
      return {
        ...state,
        suggestedSkillset: action.payload,
      };
    },
    setScrollPosition(state, action: PayloadAction<number | undefined>) {
      return {
        ...state,
        scrollPosition: action.payload,
      };
    },
    setCurrentSort(state, action: PayloadAction<string | undefined>) {
      return {
        ...state,
        currentSort: action.payload,
      };
    },
    setDefaultFilter(state, action: PayloadAction<IJobSuggestion[]>) {
      return {
        ...state,
        defaultFilter: action.payload,
        defaultFilterSet:
          action.payload && action.payload.length > 0 ? true : false,
      };
    },
    resetCurrentFilter(state) {
      return {
        ...state,
        requestFilter: {
          ...state.requestFilter,
          currentFilterType: undefined,
        },
      };
    },
    setKeywordSearchTerm(state, action: PayloadAction<string>) {
      return {
        ...state,
        requestFilter: {
          ...state.requestFilter,
          keywordSearch: action.payload,
        },
      };
    },
    setLocationSearchTerm(state, action: PayloadAction<string>) {
      return {
        ...state,
        requestFilter: {
          ...state.requestFilter,
          locationSearch: action.payload,
        },
      };
    },
    setRecommendedJobs(state, action: PayloadAction<IACSJobDetails[]>) {
      return {
        ...state,
        recommendedJobs: action.payload,
      };
    },
    getRecruiterPicks(state) {},
    setRrecruiterPicksJobs(
      state,
      action: PayloadAction<IACSJobSearchResponse | undefined>,
    ) {
      return {
        ...state,
        recruiterPicksJobs: action.payload,
      };
    },
    setAppliedDateForJob(state) {
      state.selectedJobDetails!.appliedDate = new Date(
        Date.now(),
      ).toISOString();
    },
    setScrollToTop(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        scrollToTop: action.payload,
      };
    },
    setActiveSavedSearch(state, action: PayloadAction<ISavedSearch>) {
      return {
        ...state,
        activeSavedSearch: action.payload,
      };
    },
    setActiveJobSearchTab(state, action: PayloadAction<string | undefined>) {
      return {
        ...state,
        activeJobSearchTab: action.payload,
      };
    },
    setEditSavedSearch(state, action: PayloadAction<ISavedSearch>) {
      return {
        ...state,
        editSavedSearchItem: action.payload,
      };
    },
    setSourcePage(state, action: PayloadAction<MenuIcons | undefined>) {
      return {
        ...state,
        sourcePage: action.payload,
      };
    },
    setScrollToView(state, action: PayloadAction<string>) {
      return {
        ...state,
        scrollToView: action.payload,
      };
    },
  },
});

export const cognitiveJobActions = {
  ...cognitiveJobSlice.actions,
  fetchJobs,
  fetchSavedSearchJobs,
  getJobDetailsAction,
  fetchJobSuggestionsAction,
  fetchDefaultFilterAction,
  fetchRecommendedJobs,
  postSavedSearch,
};

export default cognitiveJobSlice.reducer;
