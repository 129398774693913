import {
  Box,
  Input,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import React, { useEffect, useState } from 'react';
import ICountry from 'interfaces/Common/ICountry';
const flagSvgs = require.context('assets/icons/Flags', true, /\.svg$/);
const svgObj = flagSvgs.keys().reduce((images, path) => {
  images[path] = flagSvgs(path);
  return images;
}, {});
export const SearchBar = ({ items, onChange }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState<ICountry[]>([]);
  useEffect(() => {
    const results = items.filter(item =>
      item.countryName.toLowerCase().includes(searchTerm.toLowerCase()),
    );
    setSearchResults(results);
  }, [searchTerm]);

  return (
    <>
      <Box
        border={1}
        px={2}
        py={1}
        sx={{
          display: 'flex',
          width: '100%',
          borderRadius: '3px',
          borderColor: 'grayscale.gray500',
          alignItems: 'center',
        }}
      >
        <SearchIcon sx={{ mr: 2, width: '18px', height: '18px' }} />
        <Input
          sx={{ width: '100%' }}
          placeholder="Search"
          value={searchTerm}
          onChange={event => setSearchTerm(event.target.value)}
        />
      </Box>
      <List sx={{ maxHeight: 300 }}>
        {searchResults?.map((option, index) => (
          <ListItem
            key={index}
            sx={{
              padding: 0,
              paddingTop: 2,
              paddingBottom: 2,
            }}
            secondaryAction={
              <Typography variant="subtitle1">{option.countryCode}</Typography>
            }
            onClick={e => onChange(option)}
          >
            <ListItemIcon>
              <img
                width="30px"
                src={svgObj[`./${option.countryCode.toUpperCase()}.svg`]}
                alt=""
              />
            </ListItemIcon>

            <ListItemText primary={option.countryName} />
          </ListItem>
        ))}
      </List>
    </>
  );
};
export default SearchBar;
