import { trackPromise } from 'react-promise-tracker';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import { httpSuccess } from 'helpers/serviceHelper';
import { IErrorResponseData } from 'interfaces/Common/IErrorResponse';
import { logger } from 'services/logging/appInsights';
import { userIdSelector } from 'store/selectors/authSelector';
import { openAlert } from 'store/slices/alertbar/alertbarSlice';
import { payStatementsActions } from 'store/slices/payStatement/payStatementSlice';
import { TOAST_MESSAGE } from 'constants/helperText';
import { AppUserType } from 'enums/AppUserType';
import store from '../../configureStore';
import {
  fetchPayStatements,
  fetchPayStatementDownload,
} from 'services/payStatement/payStatementService';
import { IFileDetails } from 'interfaces/Credential/ICredentialDetails';
import { downloadBase64 } from 'utils/helper';

const trackedPayStatementData = (fn, ...args) =>
  trackPromise(fn(...args), 'get-pay-statements');

const trackedPayStatementDownload = (fn, ...args) =>
  trackPromise(fn(...args), 'download-pay-statements');

function* getPayStatementData(action) {
  try {
    const userId = yield select(userIdSelector);
    const userType = store.getState()?.auth?.userType;
    const isLocums = userType === AppUserType.LOCUMS ? true : false;

    const response = yield call(trackedPayStatementData, fetchPayStatements, {
      userId,
      isLocums: isLocums,
    });
    const { data } = response;
    if (response && httpSuccess(response?.status)) {
      if (data) {
        const { statements } = data;

        yield put(
          payStatementsActions.setPayStatementState({
            payStatements: statements != null ? statements : [],
          }),
        );
      }
    }
  } catch (error: any) {
    const err = error.data as IErrorResponseData;
    yield put(
      openAlert({
        variant: 'error',
        message: err?.message || TOAST_MESSAGE.SomethingWentWrongTryAgain,
      }),
    );
    logger.error(error, 'getPayStatementData', 'payStatementSaga.ts');
  }
}

function* getPayStatementDownload(action) {
  try {
    const userId = yield select(userIdSelector);
    const paymentId = action.payload.paymentId;
    const userType = store.getState()?.auth?.userType;
    const isLocums = userType === AppUserType.LOCUMS ? true : false;
    const response = yield call(
      trackedPayStatementDownload,
      fetchPayStatementDownload,
      {
        userId,
        paymentId,
        isLocums: isLocums,
      },
    );
    const { data } = response;
    if (response && httpSuccess(response?.status) && data) {
      if (data) {
        const file: IFileDetails = {
          filename: 'pay-statement.pdf',
          fileType: '.pdf',
          data: data.file,
        };

        downloadBase64(file);
      }
    }
  } catch (e: any) {
    yield put(
      openAlert({
        variant: 'error',
        message: e?.message || TOAST_MESSAGE.SomethingWentWrongTryAgain,
      }),
    );
    logger.error(e, 'getPayStatementDownload', 'payStatementSaga.ts');
  }
}

export function* payStatementSaga() {
  yield all([
    takeLatest(
      payStatementsActions.getPayStatementsAction.type,
      getPayStatementData,
    ),
    takeLatest(
      payStatementsActions.getPayStatementDownloadAction.type,
      getPayStatementDownload,
    ),
  ]);
}
