import { IErrorResponse } from 'interfaces/Common/IErrorResponse';
import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import * as userService from 'services/user/userService';
import { RootState } from 'store/configureStore';
import { actionItemActions } from 'store/slices/actionItem/actionItemSlice';
import {
  addWorkHistory,
  invokeEditWorkHistory,
  onAddWorkHistorySuccess,
  setEditWorkHistory,
  setError,
  invokeUpdateWorkHistory,
  onUpdateWorkHistorySuccess,
} from 'store/slices/workHistory/workHistorySlice';
import {
  createWorkHistory,
  editWorkHistory,
} from '../../../../services/profile/workHistoryService';
import { logger } from 'services/logging/appInsights';

const selectedClearanceItem = (state: RootState) =>
  state.actionItem.selectedClearanceItem;

function* invokeRequestEditWorkHistory(action) {
  try {
    const response = yield call(
      f => userService.editUserWorkHistory(f.userId, f.editEducation),
      action.payload,
    );
    const { data } = response;
    yield put(setEditWorkHistory(data));

    const clearanceItem = yield select(selectedClearanceItem);
    if (clearanceItem && clearanceItem.id) {
      yield put(actionItemActions.dismissActionItemForClearanceItem());
    }
  } catch (error: any) {
    const err = error.data as IErrorResponse;
    yield put(setError(err));
    logger.error(error, 'invokeRequestEditWorkHistory', 'workHistorySaga.ts');
  }
}

function* invokeUpdateWorkHistorySaga(action) {
  try {
    const response = yield call(
      f => editWorkHistory(f.userId, f.payload),
      action.payload,
    );
    const { data } = response;
    yield put(onUpdateWorkHistorySuccess(data));
    const clearanceItem = yield select(selectedClearanceItem);
    if (clearanceItem && clearanceItem.id) {
      yield put(actionItemActions.dismissActionItemForClearanceItem());
    }
  } catch (error: any) {
    const err = error.data as IErrorResponse;
    yield put(setError(err));
  }
}

function* invokeAddWorkHistory(action) {
  try {
    const response = yield call(
      f => createWorkHistory(f.userId, f.payload),
      action.payload,
    );
    const { data } = response;
    yield put(onAddWorkHistorySuccess(data));

    const clearanceItem = yield select(selectedClearanceItem);
    if (clearanceItem && clearanceItem.id) {
      yield put(actionItemActions.dismissActionItemForClearanceItem());
    }
  } catch (error: any) {
    const err = error.data as IErrorResponse;
    yield put(setError(err));
    logger.error(error, 'invokeAddWorkHistory', 'workHistorySaga.ts');
  }
}

function* editWorkHistoryWatcher() {
  yield takeEvery(invokeEditWorkHistory.type, invokeRequestEditWorkHistory);
}

function* addWorkHistoryWatcher() {
  yield takeEvery(addWorkHistory.type, invokeAddWorkHistory);
}

function* updateWorkHistoryWatcher() {
  yield takeEvery(invokeUpdateWorkHistory.type, invokeUpdateWorkHistorySaga);
}

export function* workHistorySaga() {
  yield all([
    editWorkHistoryWatcher(),
    addWorkHistoryWatcher(),
    updateWorkHistoryWatcher(),
  ]);
}
